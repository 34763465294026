// application
import enMessages from './messages/en.json';
import zhMessages from './messages/zh.json';
import rtlMessages from './messages/rtl.json';

export default {
    EN: {
        messages: enMessages,
        direction: 'ltr',
    },
    ZH: {
        messages: zhMessages,
        direction: 'ltr',
    },
    ar: {
        messages: rtlMessages,
        direction: 'rtl',
    },
};
