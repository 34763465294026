import { getHeader, paramBuilder } from "../services/utils";
import { getMemberCode } from "./auth";
const axios = require("axios");

export async function addOrder(data) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/add_order/" + getMemberCode();
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function calcDeliveryCharges(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/delivery_charges/" + getMemberCode(), data, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getOrders(params) {
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/order_listing/" + getMemberCode() + queryString;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getOrderDetails(refNo) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/order_detail/" + getMemberCode() + "/" + refNo;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

//will get eGHL_url field
export function getOrderPaymentUrl(refNo) {
    return new Promise((resolve, reject) => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    process.env.REACT_APP_API_PREFIX +
                    "/get_update_order_status_paid_eGHL_url/" +
                    getMemberCode() +
                    "/" +
                    refNo,
                {
                    headers: getHeader(),
                    timeout: 180000,
                }
            )
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function cancelOrder(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(
                process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/update_order_status_cancelled/" + getMemberCode(),
                data,
                {
                    headers: getHeader(),
                    timeout: 180000,
                }
            )
            .then((response) => {
                const { data } = response;
                if (data.Status == 1) {
                    resolve(data);
                } else {
                    throw data;
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function updateOrderConfirm(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(
                process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/update_order_status_comment/" + getMemberCode(),
                data,
                {
                    headers: getHeader(),
                    timeout: 180000,
                }
            )
            .then((response) => {
                const { data } = response;
                if (data.Status == 1) {
                    resolve(data);
                } else {
                    throw data;
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getPurchaseHistory(params) {
    let url =
        process.env.REACT_APP_API_URL +
        process.env.REACT_APP_API_PREFIX +
        "/get_purchase_history_data/" +
        getMemberCode() +
        "/" +
        params.fromDate +
        "/" +
        params.toDate;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function reviewThisOrder(data, productCode) {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/add_review/" + productCode, [data], {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                if (data.Status == 1) {
                    resolve(data);
                } else {
                    throw data;
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function printOnlineBill(orderRefNo) {
    return new Promise((resolve, reject) => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    process.env.REACT_APP_API_PREFIX +
                    "/print_customer_bill/" +
                    getMemberCode() +
                    "/" +
                    orderRefNo,
                {
                    headers: getHeader(),
                    timeout: 180000,
                }
            )
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function printOfflineBill(orderTransNo, billType2) {
    return new Promise((resolve, reject) => {
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    process.env.REACT_APP_API_PREFIX +
                    "/print_offline_bill/" +
                    getMemberCode() +
                    "/" +
                    orderTransNo +
                    "/" +
                    billType2,
                {
                    headers: getHeader(),
                    timeout: 180000,
                }
            )
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

//offline sales purchase items
export function viewPurchaseItem(params) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/view_purchase_item_bybill/" + params.transno;
    if (params?.type1) url += "/" + params.type1;
    if (params?.type2) url += "/" + params.type2;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function updateOrderStatusPaid(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/update_order_status_paid/" + getMemberCode(), data, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                if (data.Status == 1) {
                    resolve(data);
                } else {
                    throw data;
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

//To check those under Processing Payment bills already paid in eghl?
// Due to always happening money deducted but xeersoft shows processing payment due to callbackURL not called.
export function checkEghlPayment() {
    return new Promise((resolve, reject) => {
        axios
            .get(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/payment_request_eghl/" + getMemberCode(), {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

//Allow user to change item qty or delete item when out of stock alert so they still able to proceed for payment
export function changeOrderItemQty(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(
                process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/update_ecom_bill_detail_header/" + getMemberCode(),
                data,
                {
                    headers: getHeader(),
                    timeout: 180000,
                }
            )
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function updatePickUpDateTime(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(
                process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/update_pickup_datetime/" + getMemberCode(),
                data,
                {
                    headers: getHeader(),
                    timeout: 180000,
                }
            )
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
