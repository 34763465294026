// react
import React, { useState, useEffect } from "react";

// third-party
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { getMemberCode } from "../../api/auth";

// application
import DropdownLanguage from "./DropdownLanguage";
import { useSelector, useDispatch } from "react-redux";
import { openDeliveryModal } from "../../store/user";
import { PICKUP_METHOD } from "../../data/generalData";

function Topbar() {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    let deliveryMethod = useSelector((state) => state?.user?.deliveryMethod);

    const links = [
        // { title: <FormattedMessage id="topbar.aboutUs" defaultMessage="About Us" />, url: "/about-us" },
        // { title: <FormattedMessage id="topbar.contacts" defaultMessage="Contacts" />, url: "/contact-us" },
        // { title: <FormattedMessage id="topbar.outlets" defaultMessage="Outlets" />, url: "/outlets" },
        // { title: <FormattedMessage id="topbar.trackOrder" defaultMessage="Track Order" />, url: "/shop/track-order" },
        // { title: <FormattedMessage id="topbar.blog" defaultMessage="Blog" />, url: "/blog/category-classic" },
    ];

    const linksList = links.map((item, index) => (
        <div key={index} className="topbar__item topbar__item--link">
            <Link className="topbar-link" to={item.url}>
                {item.title}
            </Link>
        </div>
    ));

    const openModal = (visible) => {
        dispatch(openDeliveryModal(visible));
    };

    const returnDisplayMethod = () => {
        let method = "";
        if (deliveryMethod.type == PICKUP_METHOD) {
            method = intl.formatMessage({ id: "pickup", defaultMessage: "PICKUP" });
        } else method = intl.formatMessage({ id: "delivery", defaultMessage: "DELIVERY" });

        return method + (deliveryMethod?.store?.store_name ? " - " + deliveryMethod?.store?.store_name : "");
    };

    return (
        <div className="site-header__topbar topbar">
            <div className="topbar__container container">
                <div className="topbar__row">
                    {linksList}
                    <div className="topbar__spring" />
                    {/* 
                    <div className="topbar__item">
                        <DropdownCurrency />
                    </div> */}
                    <div className="topbar__item">
                        <DropdownLanguage />
                    </div>
                    {getMemberCode() ? (
                        <>
                            <div className="pl-2 pr-2"> | </div>
                            <div className="topbar__item" onClick={() => openModal(true)}>
                                <div className="topbar__item-value link c-pointer">{returnDisplayMethod()}</div>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default Topbar;
