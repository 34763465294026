// react
import React from "react";
import { FormattedMessage } from "react-intl";

export default function FooterAboutUs() {
    return (
        <div className="site-footer__widget footer-about">
            <h5 className="footer-about__title">天天新鲜 时刻分享</h5>
            {/* <Link to="/">
                <img src="images/logos/logo.png" alt="logo" className="footer-logo" />
            </Link> */}

            <div className="footer-about__text">
                <FormattedMessage
                    id="footer.aboutDesc"
                    defaultMessage="Hup Soon is a retail convenience store chain that specialises in fresh pork and pork products. In addition to pork
                products, Hup soon also stock and sell various dried foods and frozen foods items for a holistic shopping experience."
                />
            </div>
            <div className="py-2">
                <h5 className="footer-about__title mb-2">
                    <FormattedMessage id="footer.downloadApp" defaultMessage="Download Hup Soon App at" />
                </h5>
                <div className="d-flex flex-wrap site-footer__social-icons">
                    <a
                        className="align-self-center"
                        href="https://play.google.com/store/apps/details?id=com.xeersoft.hupsoonecommerce"
                        target="_blank"
                    >
                        <img src="images/web-images/playstore.png" alt="play-store-download" className="store-logo" />
                    </a>
                    <a className="align-self-center mx-1" href="https://apps.apple.com/my/app/hup-soon-my/id1624638336" target="_blank">
                        <img src="images/web-images/appstore.png" alt="app-store-download" className="store-logo" />
                    </a>
                    <a className="align-self-center" href="https://appgallery.huawei.com/app/C107434375" target="_blank">
                        <img
                            src="images/web-images/AppGallery-Badge-Black-1024x441.png"
                            alt="app-gallery-download"
                            className="store-logo"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
}
