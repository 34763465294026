// react
import React from 'react';

// third-party
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// application
import Dropdown from './Dropdown';
import { changeUserLanguage } from '../../store/locale';
import { languages } from '../../data/generalData';

function DropdownLanguage(props) {
    const { locale, changeUserLanguage: changeLocale } = props;

    const language = languages.find((x) => x.code === locale);

    const title = (
        <React.Fragment>
            <FormattedMessage id="topbar.language" defaultMessage="Language" />
            {': '}
            <span className="topbar__item-value">{language.code}</span>
        </React.Fragment>
    );

    return (
        <Dropdown
            title={title}
            withIcons
            items={languages}
            onClick={(item) => changeLocale(item.code)}
        />
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    changeUserLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownLanguage);
