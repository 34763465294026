// react
import React, { useState, useEffect } from "react";

// third-party
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

// application
import Indicator from "./Indicator";
import { Cross20Svg, Person20Svg } from "../../svg";
import { logoutSuccess, removeUserFromStorage, getMemberCode } from "../../api/auth";
import { getUserDetail } from "../../api/user";
import { mobileUserMenuOpen } from "../../store/mobile-menu";
import { checkUnauthorized, getDefaultAvatar, showGeneralError, useWindowSize } from "../../services/utils";
import { useSelector, useDispatch } from "react-redux";
import accountMenuList from "../../data/accountMenuList";
import OpenReplay from "@openreplay/tracker";
import trackerAxios from "@openreplay/tracker-axios";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { claimVoucher } from "../../api/voucher";
import { getMyLanguage } from "../../api/general";
import { localeChange } from "../../store/locale";

const defaultAvatar = getDefaultAvatar();
const tracker = new OpenReplay({
    projectKey: process.env.REACT_APP_PROJECT_KEY,
    ingestPoint: process.env.REACT_APP_OPENREPLAY_URL,
    // onStart: ({ sessionID }) => console.log("OpenReplay tracker started with session: ", sessionID),
});

// make sure only start tracker on prod site & not headless user agent
function isValidToStartRecord() {
    return (
        process.env.REACT_APP_PROJECT_KEY &&
        process.env.REACT_APP_OPENREPLAY_URL &&
        process.env.NODE_ENV == "production" &&
        /^.*(headless).*/gi.test(window?.navigator?.userAgent) == false &&
        window.location.pathname !== "/goto-404" &&
        window.location.pathname !== "/404-not-found"
    );
}

if (isValidToStartRecord()) {
    tracker.start();
    tracker.use(trackerAxios({ failuresOnly: false }));
    tracker.setMetadata("env", "uat");
}

export default function IndicatorAccount(props) {
    const code = getMemberCode();
    const { from } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        handleSubmit,
        register,
        formState: { errors },
        setError,
    } = useForm();
    const [apiLoading, setApiLoading] = useState(false);
    let user = useSelector((state) => state?.user?.profile);
    const windowSize = useWindowSize();
    let avatar = user?.customer_picture ? user?.customer_picture : null;
    const [imgError, setImgError] = useState(false);
    const [closeIndicator, setCloseIndicator] = useState(false);
    const [birthdayVoucherModalVisible, setBirthdayVoucherModalVisible] = useState(false);

    // get user profile if code changed
    useEffect(() => {
        document.getElementById("account-menu__form")?.reset();
        if (code && from === "header") {
            getUserDetail()
                .then((res) => {
                    if (res) {
                        if (res.birthday_claimed == 0 && res.is_birthday == 1) {
                            setBirthdayVoucherModalVisible(true);
                        }
                        getMyLanguage().then((res) => {
                            dispatch(localeChange(res.language));
                        });
                        if (isValidToStartRecord()) tracker.setUserID(res.customer_firstname + "(" + res.customer_mobile + ")");
                    }
                })
                .catch((err) => {
                    showGeneralError(err);
                    removeUserFromStorage();
                    history.push("/login");
                });
        }
    }, [code]);

    function handleLogout() {
        toast.info(<FormattedMessage id="logout_success_toast" defaultMessage="Logout Successful" />, { autoClose: 2000 });
        handleCloseAfterRedirect();
        logoutSuccess(history);
    }

    const handleCloseAfterRedirect = () => {
        setCloseIndicator(true);
        setTimeout(() => {
            setCloseIndicator(false);
        }, 500);
    };

    const claimBirthdayVoucher = () => {
        claimVoucher({
            total_redemption: "0",
            voucher_info: "0",
        }).then((res) => {
            if (res.voucher_created == 1) {
                setBirthdayVoucherModalVisible(false);
                history.push("/account/vouchers");
            } else showGeneralError(res);
        });
    };

    function renderBirthdayVoucherModal() {
        return (
            <Modal
                size="md"
                isOpen={birthdayVoucherModalVisible && window.location.pathname == "/"}
                toggle={() => setBirthdayVoucherModalVisible(false)}
                centered
            >
                <ModalBody className="text-center">
                    <span className="modal-close-btn" onClick={() => setBirthdayVoucherModalVisible(false)}>
                        <Cross20Svg />
                    </span>
                    <div className="p-3">
                        <img src={"/images/icons/birthday_cake.png"} style={{ height: 120 }} />
                        <p>
                            <FormattedMessage id="birthday_modal_header" defaultMessage="Happy Birthday" />,{" "}
                            <b>{user?.customer_firstname}</b>!
                        </p>
                        <p>
                            <FormattedMessage
                                id="birthday_modal_body"
                                defaultMessage="To show our applications to our beloved customer, claim your birthday voucher now for an exiciting promotion."
                            />
                        </p>
                        <button className="btn btn-primary" onClick={() => claimBirthdayVoucher()}>
                            <FormattedMessage id="birthday_modal_button" defaultMessage="Claim My Birthday Voucher" />
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    const accountDropdown = (
        <div className="account-menu">
            <div className="account-menu__divider" />
            <Link to="/account/profile" className="account-menu__user">
                <div className="account-menu__user-avatar">
                    {imgError ? (
                        <img src={getDefaultAvatar()} alt="user-avatar" />
                    ) : (
                        <img src={"https://" + avatar} alt="user-avatar" onError={(e) => setImgError(true)} />
                    )}
                </div>
                <div className="account-menu__user-info">
                    <div className="account-menu__user-name">{user?.customer_firstname}</div>
                    <div className="account-menu__user-email">
                        {user?.customer_point} <FormattedMessage id="point" defaultMessage="points" />
                    </div>
                </div>
            </Link>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                {accountMenuList.map((item) => {
                    return (
                        <li key={item.url}>
                            <Link to={item.url}>{item.name}</Link>
                        </li>
                    );
                })}
            </ul>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                <Link to="" onClick={handleLogout}>
                    <FormattedMessage id="logout" defaultMessage="Log Out" />
                </Link>
            </ul>
        </div>
    );

    return (
        <>
            {renderBirthdayVoucherModal()}
            {!code ? (
                <Indicator url="/login" icon={<Person20Svg />} />
            ) : from == "mobile" ? (
                <div onClick={() => dispatch(mobileUserMenuOpen())}>
                    <Indicator icon={<Person20Svg />} />
                </div>
            ) : (
                <Indicator url="/account" closeIndicator={closeIndicator} dropdown={accountDropdown} icon={<Person20Svg />} />
            )}
        </>
    );
}
