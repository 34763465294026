import { decodeChineseText, getHeader } from "../services/utils";
import { getMemberCode } from "./auth";
import { cartAddItemSuccess, cartRemoveItemSuccess, syncMyCart } from "../store/cart";
import store from "../store";
const axios = require("axios");

export async function getMyCart() {
    let selectedPickUpStore = store.getState().user.deliveryMethod?.store?.store_no;
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/get_cart/" + getMemberCode()+"?store="+selectedPickUpStore;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
                store.dispatch(syncMyCart(data));
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function addCart(product, qty, history) {
    let passData = {
        product_code: product.product_code,
        product_number: qty,
    };
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/add_cart/" + getMemberCode(), [passData], {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                let tempProd = {
                    ...product,
                    product_name: decodeChineseText(product.product_name ? product.product_name : product.product_description),
                };
                store.dispatch(cartAddItemSuccess(tempProd, qty, history));
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function removeCart(passData) {
    return new Promise((resolve, reject) => {
        axios
            .post(
                process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/clear_cart/" + getMemberCode(),
                passData ? [passData] : [],
                {
                    headers: getHeader(),
                    timeout: 180000,
                }
            )
            .then((response) => {
                const { data } = response;
                store.dispatch(cartRemoveItemSuccess(passData ? passData?.product_code : 0));
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
