import { getHeader } from "../services/utils";
import { getMemberCode } from "./auth";
const axios = require("axios");

export async function getMemberVoucher() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/member_voucher/" + getMemberCode();
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getExpiryVoucher() {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/expiry_voucher/" + getMemberCode();
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function claimVoucher(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/get_voucher/" + getMemberCode(), data, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function validateProductVoucher(data, voucherCode) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/validate_product_voucher/" + voucherCode;
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
