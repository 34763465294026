// react
import React, { useEffect, useReducer, useState } from "react";

// third-party
import PropTypes from "prop-types";
import queryString from "query-string";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import {  useIntl } from "react-intl";

// application
import BlockLoader from "../blocks/BlockLoader";
import PageHeader from "../shared/PageHeader";
import ProductsView from "./ProductsView";
import { sidebarClose } from "../../store/sidebar";

// data stubs
import theme from "../../data/theme";
import { getProductList } from "../../api/product";
import { useHistory } from "react-router-dom";
import { ProductTypeList } from "../../data/generalData";
const perPage = 25;

function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};

    if (typeof query.page === "string") {
        optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === "string") {
        optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === "string") {
        optionValues.sort = query.sort;
    }

    return optionValues;
}

function parseQueryFilters(location) {
    const query = queryString.parse(location);
    const filterValues = {};

    Object.keys(query).forEach((param) => {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            return;
        }

        const filterSlug = mr[1];

        filterValues[filterSlug] = query[param];
    });

    return filterValues;
}

function parseQuery(location) {
    return [parseQueryOptions(location), parseQueryFilters(location)];
}

function buildQuery(options, filters) {
    const params = {};

    if (options.sort !== "default") {
        params.sort = options.sort;
    }

    Object.keys(filters)
        .filter((x) => x !== "category" && !!filters[x])
        .forEach((filterSlug) => {
            params[`filter_${filterSlug}`] = filters[filterSlug];
        });

    return queryString.stringify(params, { encode: false });
}

const initialState = {
    init: false,
    /**
     * Indicates that the category is loading.
     */
    brandIsLoading: true,
    /**
     * Category object.
     */
    brands: null,
    /**
     * Indicates that the products list is loading.
     */
    productsListIsLoading: true,
    /**
     * Products list.
     */
    productsList: null,
    /**
     * Products list options.
     *
     * options.page:  number - Current page.
     * options.limit: number - Items per page.
     * options.sort:  string - Sort algorithm.
     */
    options: {
        sort: "product_topsales",
        limit: "0," + perPage,
    },
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},
};

function reducer(state, action) {
    switch (action.type) {
        case "FETCH_BRANDS_SUCCESS":
            return {
                ...state,
                init: true,
                brandIsLoading: false,
                brands: action.brands,
            };
        case "FETCH_PRODUCTS_LIST":
            return { ...state, productsListIsLoading: true };
        case "FETCH_PRODUCTS_LIST_SUCCESS":
            return { ...state, productsListIsLoading: false, productsList: action.productsList };
        case "SET_OPTION_VALUE":
            return {
                ...state,
                options: { ...state.options, limit: "0," + perPage, [action.option]: action.value },
            };
        case "SET_FILTER_VALUE":
            return {
                ...state,
                options: { ...state.options, limit: "0," + perPage },
                filters: { ...state.filters, [action.filter]: action.value },
            };
        case "RESET_FILTERS":
            return { ...state, options: { ...state.options, limit: "0," + perPage }, filters: {} };
        case "RESET":
            return state.init ? initialState : state;
        default:
            throw new Error();
    }
}

function init(state) {
    const [options, filters] = parseQuery(window.location.search);

    return { ...state, options, filters };
}

function ShopPageProductList(props) {
    const intl = useIntl();
    const { slug, columns, viewMode, sidebarPosition } = props;
    const offcanvas = columns === 3 ? "mobile" : "always";
    const history = useHistory();
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const [filterParams, setFilterParams] = useState(null);
    const [doneLoad, setDoneLoad] = useState(false);
    let selectedType = ProductTypeList.find((item) => item.slug == slug);
    // Replace current url.
    useEffect(() => {
        const query = buildQuery(state.options, state.filters);
        const location = `${window.location.pathname}${query ? "?" : ""}${query}`;
    }, [state.options, state.filters]);

    useEffect(() => {
        let apiParams = {
            limit: "0," + perPage,
        };
        if (slug) {
            if (selectedType && selectedType.paramField == "type") {
                apiParams.type = selectedType.paramName;
            } else if (selectedType && selectedType.paramField == "sort") {
                apiParams.sort = selectedType.paramName;
            }
        }

        setDoneLoad(false);
        dispatch({ type: "RESET_FILTERS" });
        dispatch({ type: "FETCH_PRODUCTS_LIST_SUCCESS", productsList: [] });
        setFilterParams(apiParams);
    }, [slug]);

    // Load products.
    useEffect(() => {
        dispatch({ type: "FETCH_PRODUCTS_LIST" });
        if (state.options.sort) filterParams.sort = state.options.sort;
        if (state.options.limit) filterParams.limit = state.options.limit;
        if (filterParams) {
            getProductList(filterParams).then((productsList) => {
                let list = productsList;
                if (state.options.limit !== "0," + perPage && state.productsList) {
                    list = [...state.productsList, ...list];
                }
                if (productsList.length == 0 || list.length < parseInt(filterParams.limit?.split(",")[1])) setDoneLoad(true);
                dispatch({ type: "FETCH_PRODUCTS_LIST_SUCCESS", productsList: list });
                
            });
        }
    }, [dispatch, filterParams, state.options, state.filters]);

    if (state.productsListIsLoading && !state.productsList) {
        return <BlockLoader />;
    }

    let pageTitle = intl.formatMessage({ id: slug + "_prod", defaultMessage: selectedType?.name });
    let content;

    const productsView = (
        <ProductsView
            isLoading={state.productsListIsLoading}
            productsList={state.productsList}
            options={state.options}
            filters={state.filters}
            dispatch={dispatch}
            layout={viewMode}
            grid={`grid-${columns}-${columns > 3 ? "full" : "sidebar"}`}
            offcanvas={offcanvas}
            doneLoad={doneLoad}
            noFilter
            noSort
        />
    );

    content = (
        <div className="container">
            <div className="block">{productsView}</div>
        </div>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${intl.formatMessage({ id: "search_page", defaultMessage: "Search" })} — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header={pageTitle} breadcrumb={[]} />
            {content}
        </React.Fragment>
    );
}

ShopPageProductList.propTypes = {
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageProductList.defaultProps = {
    columns: 3,
    viewMode: "grid",
    sidebarPosition: "start",
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    page: state.category,
});

const mapDispatchToProps = () => ({
    sidebarClose,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageProductList);
