// react
import React, { useEffect, useState, useRef } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

// data stubs
import { capitalizeFirstLetter, showGeneralError } from "../../services/utils";
import { Check9x7Svg } from "../../svg";
import { Link, useHistory } from "react-router-dom";
import { updateAddress, addAddress, getAddress } from "../../api/user";
import { getStates } from "../../api/general";
import BlockLoader from "../blocks/BlockLoader";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import theme from "../../data/theme";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function AddressForm(props) {
    const {
        handleSubmit,
        register,
        formState: { errors },
        setError,
    } = useForm();
    const { match, location, address, submitAddress } = props;
    const history = useHistory();
    const intl = useIntl();
    const [stateList, setStateList] = useState([]);
    const [countryList, setCountryList] = useState(useSelector((state) => state?.generalInfo?.countries));
    const [apiLoading, setApiLoading] = useState(countryList[0] ? false : true);
    const [errorMsg, setErrorMsg] = useState("");
    const [mobileNumber, setMobileNumber] = useState(address?.add_phone ? address?.add_phone : "");

    useEffect(() => {
        getStates().then((res) => {
            if (res?.length > 0) setStateList(res);
            setApiLoading(false);
        });
    }, []);

    const onSubmit = (data) => {
        setApiLoading(true);
        setErrorMsg("");
        data.add_country = "Malaysia";
        submitAddress(data);
    };

    return (
        <div>
            {apiLoading || stateList?.length == 0 ? (
                <BlockLoader />
            ) : (
                <form className="row no-gutters" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-12 col-lg-10 col-xl-8">
                        <div className="form-group">
                            <label htmlFor="first_name">
                                <FormattedMessage id="name" defaultMessage="Name" />
                            </label>
                            <input
                                {...register("add_person", {
                                    required: {
                                        value: true,
                                        message: <FormattedMessage id="field_required" defaultMessage="First Name is required" />,
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: (
                                            <FormattedMessage
                                                id="value_max_100"
                                                defaultMessage="First Name should not exceed 100 characters"
                                            />
                                        ),
                                    },
                                })}
                                type="text"
                                className={`form-control ${errors.add_person == null ? "" : "is-invalid"}`}
                                id="add_person"
                                name="add_person"
                                defaultValue={!address ? "" : address.add_person}
                                placeholder=""
                            />
                            {errors.add_person == null ? "" : <div className="invalid-feedback">{errors.add_person.message}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="address-mobile">
                                <FormattedMessage id="phone_no" defaultMessage="Mobile Number" />
                            </label>
                            <PhoneInput
                                inputProps={{
                                    name: "add_phone",
                                    id: "add_phone",
                                    ...register("add_phone", {
                                        required: {
                                            value: true,
                                            message: <FormattedMessage id="field_required" defaultMessage="Mobile Number is required" />,
                                        },
                                        maxLength: {
                                            value: 30,
                                            message: (
                                                <FormattedMessage
                                                    id="value_max_30"
                                                    defaultMessage="Mobile Number should not exceed 30 characters"
                                                />
                                            ),
                                        },
                                        onChange: (e) => {
                                            setMobileNumber(e.target.value);
                                        },
                                    }),
                                }}
                                inputClass={`form-control ${errors.add_phone == null ? "" : "is-invalid"}`}
                                className={`${errors.add_phone == null ? "" : "is-invalid"}`}
                                onlyCountries={["my"]}
                                country={"my"}
                                disableDropdown
                                value={mobileNumber}
                                autoFormat={false}
                                // preferredCountries={["my", "sg"]}
                                countryCodeEditable={false}
                            />
                            {errors.add_phone == null ? "" : <div className="invalid-feedback">{errors.add_phone.message}</div>}
                        </div>
                        {/* 
                            <div className="form-group">
                                <label htmlFor="country">Country</label>
                                <select
                                    {...register("add_country", { required: true })}
                                    defaultValue={address?.add_country ? address.add_country : ""}
                                    name="add_country"
                                    className={`form-control ${errors.add_country == null ? "" : "is-invalid"}`}
                                >
                                    <option key={"default"} value="">
                                        Please select country...
                                    </option>
                                    {countryList?.map((country) => (
                                        <option key={country.name} value={country.name}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                                {errors.add_country == null ? "" : <div className="invalid-feedback">Country is required</div>}
                            </div> */}

                        <div className="form-group">
                            <label htmlFor="add_address1">
                                <FormattedMessage id="street_1" defaultMessage="Street Address 1" />
                            </label>
                            <input
                                id="add_address1"
                                name="add_address1"
                                {...register("add_address1", {
                                    required: {
                                        value: true,
                                        message: <FormattedMessage id="field_required" defaultMessage="Street Address is required" />,
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: (
                                            <FormattedMessage
                                                id="value_max_100"
                                                defaultMessage="Street Address should not exceed 100 characters"
                                            />
                                        ),
                                    },
                                })}
                                className={`form-control ${errors.add_address1 == null ? "" : "is-invalid"}`}
                                placeholder="No 123, Street Example"
                                defaultValue={!address ? "" : address.add_address1}
                            />
                            {errors.add_address1 == null ? "" : <div className="invalid-feedback">{errors.add_address1.message}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="add_address2">
                                <FormattedMessage id="street_2" defaultMessage="Street Address 2" />
                            </label>
                            <input
                                id="add_address2"
                                name="add_address2"
                                {...register("add_address2", {
                                    required: {
                                        value: true,
                                        message: <FormattedMessage id="field_required" defaultMessage="Street Address is required" />,
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: (
                                            <FormattedMessage
                                                id="value_max_100"
                                                defaultMessage="Street Address should not exceed 100 characters"
                                            />
                                        ),
                                    },
                                })}
                                className={`form-control ${errors.add_address2 == null ? "" : "is-invalid"}`}
                                placeholder=""
                                defaultValue={!address ? "" : address.add_address2}
                            />
                            {errors.add_address2 == null ? "" : <div className="invalid-feedback">{errors.add_address2.message}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="add_city">
                                <FormattedMessage id="town" defaultMessage="Town / City" />
                            </label>
                            <input
                                {...register("add_city", {
                                    required: {
                                        value: true,
                                        message: <FormattedMessage id="field_required" defaultMessage="City is required" />,
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: (
                                            <FormattedMessage id="value_max_100" defaultMessage="City should not exceed 100 characters" />
                                        ),
                                    },
                                })}
                                type="text"
                                className={`form-control ${errors.add_city == null ? "" : "is-invalid"}`}
                                id="add_city"
                                name="add_city"
                                defaultValue={!address ? "" : address.add_city}
                            />
                            {errors.add_city == null ? "" : <div className="invalid-feedback">{errors.add_city.message}</div>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="add_postcode">
                                <FormattedMessage id="postcode" defaultMessage="Postcode / Zip" />
                            </label>
                            <input
                                {...register("add_postcode", {
                                    required: {
                                        value: true,
                                        message: <FormattedMessage id="field_required" defaultMessage="Postcode is required" />,
                                    },
                                    maxLength: {
                                        value: 180,
                                        message: (
                                            <FormattedMessage
                                                id="value_max_180"
                                                defaultMessage="Postcode should not exceed 180 characters"
                                            />
                                        ),
                                    },
                                    // pattern: {
                                    //     value: /^\d{5}$/,
                                    //     message: "Postcode entered is invalid",
                                    // },
                                })}
                                type="text"
                                className={`form-control ${errors.add_postcode == null ? "" : "is-invalid"}`}
                                id="add_postcode"
                                name="add_postcode"
                                defaultValue={!address ? "" : address.add_postcode}
                            />
                            {errors.add_postcode == null ? "" : <div className="invalid-feedback">{errors.add_postcode.message}</div>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="state">
                                <FormattedMessage id="state" defaultMessage="State" />
                            </label>
                            <select
                                // onChange={(e) => setState(e.target.value)}
                                defaultValue={address ? address.add_state.toLowerCase() : ""}
                                id="add_state"
                                name="add_state"
                                className={`form-control ${errors.add_state == null ? "" : "is-invalid"}`}
                                {...register("add_state", { required: true })}
                            >
                                <option key={"default"} value="">
                                    {intl.formatMessage({ id: "select_state", defaultMessage: "Please select state..." })}
                                </option>
                                {stateList.map((item) => (
                                    <option key={item.state} value={item.state.toLowerCase()}>
                                        {capitalizeFirstLetter(item.state)}
                                    </option>
                                ))}
                            </select>
                            {errors.add_state == null ? (
                                ""
                            ) : (
                                <div className="invalid-feedback">
                                    <FormattedMessage id="field_required" defaultMessage="State is required" />
                                </div>
                            )}
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <div className="form-check">
                                    <span className="form-check-input input-check">
                                        <span className="input-check__body">
                                            <input
                                                id="billing"
                                                type="checkbox"
                                                className="input-check__input"
                                                {...register("add_default")}
                                                disabled={!address ? false : address.add_default === true}
                                                defaultChecked={address?.add_default == 1 ? parseInt(address?.add_default) : 0}
                                            />
                                            <span className="input-check__box" />
                                            <Check9x7Svg className="input-check__icon" />
                                        </span>
                                    </span>
                                    <label className="form-check-label" htmlFor="billing">
                                        <FormattedMessage id="mark_default_address" defaultMessage="Make address as default" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        {errorMsg ? (
                            <div>
                                <small className="text-danger">{errorMsg}</small>
                            </div>
                        ) : null}
                        <div className="form-group mt-3 mb-0">
                            <button className={apiLoading ? "btn btn-primary btn-loading" : "btn btn-primary"} type="submit">
                                <FormattedMessage id="save" defaultMessage="Save" />
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
}
