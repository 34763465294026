import Cookies from "universal-cookie";
import moment from "moment";
import { encryptWithAES, decryptWithAES, getHeader } from "../services/utils";
import store from "../store";
const axios = require("axios");
const cookies = new Cookies();

export function login(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/customer_login", [data], {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                if (data.Status == 1) {
                    saveTokenToCookies(data);
                    resolve(data);
                } else {
                    throw data;
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function saveTokenToCookies(data) {
    var expiryDate = moment.utc().add(1, "M");
    if (data.member_code)
        cookies.set("token", encryptWithAES(data.member_code), { path: "/", expires: expiryDate._d, secure: true, sameSite: "strict" });
}

export function logoutSuccess(history) {
    removeUserFromStorage();
    setTimeout(() => {
        if (history) history.replace("/login");
        else window.location.pathname = "/login";
    }, 500);
}

export function logout(history) {
    logoutSuccess(history);
}

export function register(data) {
    data.bill_platform = "website";
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/insert_customer", data, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                if (!data.Error) {
                    resolve(data);
                } else {
                    throw data;
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function forgetPassword(data) {
    let url =
        process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/retrieve_password_by_SMS/" + data.mobile + "/" + data.mobile;

    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                headers: getHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                if (data.Status == 1) {
                    resolve(data);
                } else {
                    throw data;
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function resetPassword(data) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/reset_password";
    data.portal_type = process.env.REACT_APP_PORTAL_TYPE;

    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                headers: getHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getMemberCode() {
    let memberCode = cookies.get("token");

    if (memberCode) {
        return decryptWithAES(memberCode);
    } else {
        return 0;
    }
}

export function removeUserFromStorage() {
    cookies.remove("token", { path: "/" });
    localStorage.removeItem("shipping");
}
