// react
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import InputNumber from "../shared/InputNumber";
import PageHeader from "../shared/PageHeader";
import { Cross12Svg, Check12x9Svg } from "../../svg";
import { decodeChineseText, getFinalPrice, getNoImg, url } from "../../services/utils";

// data stubs
import theme from "../../data/theme";
import { addCart, getMyCart, removeCart } from "../../api/cart";
import BlockLoader from "../blocks/BlockLoader";

function ShopPageCart(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [quantities, setQuantities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [apiloading, setApiLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [checkoutErr, setCheckoutErr] = useState([]);
    const cart = useSelector((state) => state?.cart);
    const currency = useSelector((state) => state?.currency);
    const [cancelFn, setCancelFn] = useState(() => () => {});

    useEffect(() => {
        getMyCart().then((res) => {
            setLoading(false);
        });
    }, []);

    useEffect(() => () => cancelFn(), [cancelFn]);

    if (loading) {
        return <BlockLoader />;
    }

    function getItemQuantity(item) {
        const quantity = quantities.find((x) => x.product_code === item.product_code);

        return quantity ? quantity.value : parseInt(item.product_quantity);
    }

    const handleSelectItem = (item) => {
        let itemCodeList = [];

        itemCodeList.unshift(item.product_code);

        if (selectedItems.find((code) => code == item.product_code) !== undefined) {
            let newSelectedItems = selectedItems;
            itemCodeList.forEach((cartItemUuid) => {
                newSelectedItems = newSelectedItems.filter((code) => code !== cartItemUuid);
            });
            setSelectedItems(newSelectedItems);
        } else {
            setSelectedItems([...selectedItems, ...itemCodeList]);
        }
    };

    const handleSelectAllItem = (cartResult) => {
        if (selectedItems.length == cart.items.length) {
            setSelectedItems([]);
        } else {
            let presetList = [],
                cartItems = [];
            cartItems = cartResult ? cartResult : cart.items;
            cartItems.forEach((item) => {
                presetList.push(item.product_code);
            });
            setSelectedItems(presetList);
        }
    };

    const handleChangeQuantity = async (item, quantity) => {
        let canceled = false;
        let timer;
        const newCancelFn = () => {
            canceled = true;
            clearTimeout(timer);
        };

        if (quantity == 0) {
            removeCart({ product_code: item.product_code });
        } else {
            let stateQuantities = quantities;
            let stateQuantity = quantities.find((x) => x.product_code === item.product_code);
            const oriQty = parseInt(item.product_quantity);
            if (!stateQuantity) {
                setQuantities([...quantities, { product_code: item.product_code, value: quantity }]);
            } else {
                stateQuantities.forEach(function (obj) {
                    if (obj.product_code == item.product_code) {
                        obj.value = quantity;
                    }
                });
                setQuantities(
                    quantities.map((x) => {
                        if (x.product_code !== item.product_code) return x;
                        return { ...x, value: quantity };
                    })
                );
            }
            timer = setTimeout(() => {
                addCart(item, quantity - oriQty);
            }, 300);
        }

        setCancelFn(() => newCancelFn);
    };

    const renderItems = () => {
        return cart.items.map((item) => {
            let image;
            let options;

            image = (
                <div className="product-image">
                    <Link to={url.product(item)} className="product-image__body">
                        <img
                            className="product-image__img"
                            src={item.product_picture ? item.product_picture : getNoImg()}
                            onError={(e) => (e.target.src = getNoImg())}
                            alt=""
                        />
                    </Link>
                </div>
            );

            // if (item.options.length > 0) {
            //     options = (
            //         <ul className="cart-table__options">
            //             {item.options.map((option, index) => (
            //                 <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
            //             ))}
            //         </ul>
            //     );
            // }

            const removeButton = (
                <AsyncAction
                    action={() => removeCart({ product_code: item.product_code })}
                    render={({ run, loading }) => {
                        const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                            "btn-loading": loading,
                        });

                        return (
                            <button type="button" onClick={run} className={classes}>
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );

            return (
                <div key={item.product_code} className="cart-table__row">
                    <div className="cart-table__column cart-table__column--checkbox">
                        <span className="input-check__body cart-checkbox">
                            <input
                                className="input-check__input"
                                type="checkbox"
                                id={"checkbox" + item.product_code}
                                checked={selectedItems.find((selectedItem) => selectedItem == item.product_code) ? true : false}
                                onChange={() => {}}
                                onClick={() => handleSelectItem(item)}
                            />
                            <span className="input-check__box cart-checkbox" />
                            <Check12x9Svg className="input-check__icon cart-checkbox" />
                        </span>
                    </div>
                    <div className="cart-table__column cart-table__column--image">{image}</div>
                    <div className="cart-table__column cart-table__column--product">
                        <Link to={url.product(item)} className="cart-table__product-name">
                            {decodeChineseText(item.product_name)}
                        </Link>
                        {options}
                    </div>
                    <div className="cart-table__column cart-table__column--price" data-title="Price">
                        <Currency value={getFinalPrice(item)} />
                    </div>
                    <div className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                        <InputNumber onChange={(quantity) => handleChangeQuantity(item, quantity)} value={getItemQuantity(item)} min={0} />
                    </div>
                    <div className="cart-table__column cart-table__column--total" data-title="Total">
                        <Currency value={getFinalPrice(item) * parseInt(item.product_quantity)} />
                    </div>
                    <div className="cart-table__column cart-table__column--remove">{removeButton}</div>
                </div>
            );
        });
    };

    function returnSelectedItemsQty() {
        let qty = 0;
        if (selectedItems.length > 0) {
            selectedItems.map((item) => {
                let cartItem = cart.items.find((cartItem) => cartItem.product_code == item);
                if (cartItem) {
                    qty += cartItem ? parseInt(cartItem.product_quantity) : 0;
                }
            });
        }
        return qty;
    }

    const returnSelectedTotals = () => {
        let total = 0;
        if (selectedItems.length > 0) {
            selectedItems.forEach((code) => {
                let item = cart.items.find((cartItem) => cartItem.product_code == code);
                total += parseInt(item.product_quantity) * parseFloat(getFinalPrice(item));
            });
            return total;
        } else return total;
    };

    const renderTotals = () => {
        return (
            <div className="mb-3 subtotal">
                <div className="mt-1">
                    <FormattedMessage id="subtotal" defaultMessage="Subtotal" /> ({returnSelectedItemsQty()}{" "}
                    <FormattedMessage id="items" defaultMessage="items" />) :
                    <span className="total-price">
                        <Currency value={returnSelectedTotals()} />
                    </span>
                </div>
            </div>
        );
    };

    const selectAllCheckbox = () => {
        return (
            <span className="input-check__body cart-checkbox">
                <input
                    className="input-check__input"
                    type="checkbox"
                    id={"all-checkbox"}
                    checked={selectedItems.length > 0 && selectedItems.length == cart.items?.length}
                    disabled={cart.items.length == 0}
                    onChange={() => {}}
                    onClick={() => handleSelectAllItem()}
                />
                <span className="input-check__box cart-checkbox" />
                <Check12x9Svg className="input-check__icon cart-checkbox" />
            </span>
        );
    };

    const renderCart = () => {
        return (
            <div className="cart block">
                <div className="container">
                    <div className="cart__table cart-table">
                        <div className="cart-table__head">
                            <div className="cart-table__row">
                                <div className="cart-table__column cart-table__column--checkbox">{selectAllCheckbox()}</div>
                                <div className="cart-table__column cart-table__column--image">
                                    <FormattedMessage id="image" defaultMessage="Image" />
                                </div>
                                <div className="cart-table__column cart-table__column--product">
                                    <FormattedMessage id="product" defaultMessage="Product" />
                                </div>
                                <div className="cart-table__column cart-table__column--price">
                                    <FormattedMessage id="price" defaultMessage="Price" />
                                </div>
                                <div className="cart-table__column cart-table__column--quantity">
                                    <FormattedMessage id="quantity" defaultMessage="Quantity" />
                                </div>
                                <div className="cart-table__column cart-table__column--total">
                                    <FormattedMessage id="total" defaultMessage="Total" />
                                </div>
                                <div className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                            </div>
                        </div>
                        <div className="cart-table__body">{renderItems()}</div>
                    </div>

                    <div className="card cart-summary-bar no-gutters">
                        <div className="content">
                            <div className="cart-footer">
                                <div className="d-flex d-md-none">
                                    {selectAllCheckbox()}{" "}
                                    <div className="ml-sm-0 ml-4" style={{ marginTop: -10, fontSize: 17 }}>
                                        <FormattedMessage id="all" defaultMessage="All" />
                                    </div>
                                </div>
                                {checkoutErr ? <small className="text-danger mb-3">{checkoutErr}</small> : null}

                                {renderTotals()}
                                <Link
                                    to={{
                                        pathname: "/checkout",
                                        state: {
                                            cartItems: cart.items.filter((item) =>
                                                selectedItems.find((selectedItem) => selectedItem == item.product_code)
                                            ),
                                        },
                                    }}
                                >
                                    <button
                                        type="button"
                                        disabled={selectedItems.length == 0 || apiloading ? true : false || checkoutErr?.length > 0}
                                        className={"btn btn-primary cart__checkout-button" + (apiloading ? " btn-loading" : "")}
                                    >
                                        <FormattedMessage id="checkout" defaultMessage="Check out" />
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    let content;

    if (cart?.quantity) {
        content = renderCart();
    } else {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <img alt="empty-cart" src="/images/empty_cart.png" style={{ height: 150, marginBottom: 10 }} />
                        <div className="block-empty__message">
                            <FormattedMessage id="empty_cart_stat_1" defaultMessage="Your cart is empty." />
                            <br />
                            <FormattedMessage id="empty_cart_stat_2" defaultMessage="Start add item to your cart now." />
                        </div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm">
                                <FormattedMessage id="shop_now" defaultMessage="Shop Now" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${intl.formatMessage({ id: "shopping_cart", defaultMessage: "Shopping Cart " })} — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header={<FormattedMessage id="shopping_cart" defaultMessage="Shopping Cart" />} breadcrumb={[]} />

            {content}
        </React.Fragment>
    );
}

export default ShopPageCart;
