// react
import React, { useEffect, useState } from "react";

// application
import FooterContacts from "./FooterContacts";
import FooterLinks from "./FooterLinks";

import moment from "moment";
import FooterAbout from "./FooterAbout";
import Fab from "./Fab";

import { FormattedMessage } from "react-intl";
import { reachUsInfo } from "../../api/general";

export default function Footer(props) {
    const { socialLinks, companyContact } = props;
    const [informationLinks, setInformationLinks] = useState([
        { title: <FormattedMessage id="topbar.aboutUs" defaultMessage="About Us" />, url: "/about-us" },
        // { title: <FormattedMessage id="footer.contactUs" defaultMessage="Contact Us" />, url: "/contact-us" },
        { title: <FormattedMessage id="footer.privacyPolicy" defaultMessage="Privacy Policy" />, url: "/privacy-policy" },
        { title: <FormattedMessage id="footer.contactUs" defaultMessage="Contact Us" />, url: "/outlets" },
    ]);
    const [socialInfo, setSocialInfo] = useState(null);

    useEffect(() => {
        reachUsInfo().then((res) => {
            setSocialInfo(res);
        });
    }, []);

    const footerBottom = () => {
        return (
            <div className="site-footer__bottom">
                <div className="site-footer__copyright footer-p">
                    <p className="footer-p mb-0">
                        <FormattedMessage id="copyright.cpright" defaultMessage="Copyright" />{" "}
                        <i className="far fa-copyright" aria-hidden="true" /> {moment().format("YYYY")} Hup Soon Seremban Food Trading Sdn
                        Bhd (923403-M).
                        <FormattedMessage id="copyright.statment" defaultMessage="All Rights Reserved." />
                        <label className="p-3 d-none d-lg-inline">|</label>
                        <br className="d-lg-none"></br>
                        <a id="antinternet" target="_blank" href="https://ant-internet.com" className="develop-info c-pointer">
                            Design and Development by Ant Internet Sdn Bhd (1199944-V).
                        </a>
                    </p>
                </div>
                {/* <div className="site-footer__social-icons">
                    <ul className="social-icons-list form-row mb-0">
                        {socialLinks?.facebook_url ? (
                            <li className="social-icons">
                                <a href={socialLinks?.facebook_url} target="_blank">
                                    <i className="fab fa-facebook-f social-icon" aria-hidden="true"></i>
                                </a>
                            </li>
                        ) : null}

                        {socialLinks?.twitter_url ? (
                            <li className="social-icons">
                                <a href={socialLinks?.twitter_url} target="_blank">
                                    <i className="fab fa-twitter social-icon" aria-hidden="true"></i>
                                </a>
                            </li>
                        ) : null}

                        {socialLinks?.youtube_url ? (
                            <li className="social-icons">
                                <a href={socialLinks?.youtube_url} target="_blank">
                                    <i className="fab fa-youtube social-icon" aria-hidden="true"></i>
                                </a>
                            </li>
                        ) : null}

                        {socialLinks?.instagram_url ? (
                            <li className="social-icons">
                                <a href={socialLinks?.instagram_url} target="_blank">
                                    <i className="fab fa-instagram social-icon" aria-hidden="true"></i>
                                </a>
                            </li>
                        ) : null}

                        {socialLinks?.tiktok_url ? (
                            <li className="social-icons">
                                <a href={socialLinks?.tiktok_url} target="_blank">
                                    <i className="fab fa-tiktok social-icon" aria-hidden="true"></i>
                                </a>
                            </li>
                        ) : null}

                        {socialLinks?.flickr_url ? (
                            <li className="social-icons">
                                <a href={socialLinks?.flickr_url} target="_blank">
                                    <i className="fab fa-flickr social-icon" aria-hidden="true"></i>
                                </a>
                            </li>
                        ) : null}
                    </ul>
                </div> */}
            </div>
        );
    };

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-7 col-lg-5">
                            <FooterAbout/>
                        </div>

                        <div className="col-12 col-md-5 col-lg-3">
                            <FooterLinks
                                title={<FormattedMessage id="footer.information" defaultMessage="Information" />}
                                items={informationLinks}
                                socialInfo={socialInfo}
                            />
                        </div>

                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts companyContact={companyContact} />
                        </div>

                        {/*
                        <div className="col-6 col-md-3 col-lg-2">
                          <FooterLinks title="My Account" items={accountLinks} />
                        </div> */}
                        {/* <div className="col-12 col-md-12 col-lg-4">
                          <FooterNewsletter />
                        </div> */}
                    </div>
                </div>
                {footerBottom()}
            </div>
            <Fab whatsappNumber={"60167712327"} />
        </div>
    );
}
