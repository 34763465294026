export default {
    name: 'Hup Soon Food',
    fullName: 'Hup Soon Food',
    url: '',
    author: {
        name: 'Kos',
        profile_url: 'https://themeforest.net/user/kos9',
    },
    contacts: {
        address: '715 Fake Street, New York 10021 USA',
        email: 'stroyka@example.com',
        phone: '(800) 060-0730',
    },
};
