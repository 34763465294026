// react
import React, { useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormattedMessage, useIntl } from "react-intl";

import { showGeneralError } from "../../services/utils";
import { login } from "../../api/auth";
import theme from "../../data/theme";
import { openDeliveryModal } from "../../store/user";
import { useDispatch } from "react-redux";

export default function AccountPageLogin(props) {
    const history = useHistory();
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        handleSubmit,
        register,
        formState: { errors },
        setError,
    } = useForm();

    const [apiLoading, setApiLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const onSubmit = (data) => {
        setApiLoading(true);
        userLogin(data);
    };

    const userLogin = (data) => {
        setErrorMsg("");
        login(data)
            .then((res) => {
                toast.success(<FormattedMessage id="login_success_toast" defaultMessage="Login Successful" />, { autoClose: 3000 });
                history.push("/");
                dispatch(openDeliveryModal(true));
            })
            .catch((err) => {
                setApiLoading(false);
                if (err.Error) setErrorMsg(err.Error);
                showGeneralError(err);
            });
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${intl.formatMessage({ id: "login", defaultMessage: "Login" })} — ${theme.name}`}</title>
            </Helmet>

            <div className="block pt-5 pb-3">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">
                                        <FormattedMessage id="login" defaultMessage="Login" />
                                    </h3>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group">
                                            <label htmlFor="login-username">
                                                <FormattedMessage id="username_mobile" defaultMessage="Mobile Number" />
                                            </label>
                                            <input
                                                className={`form-control ${errors.username == null ? "" : "is-invalid"}`}
                                                id="username"
                                                name="username"
                                                type="text"
                                                placeholder={intl.formatMessage({
                                                    id: "username_mobile",
                                                    defaultMessage: "Mobile Number",
                                                })}
                                                {...register("username", {
                                                    required: {
                                                        value: true,
                                                        message: (
                                                            <FormattedMessage id="field_required" defaultMessage="Mobile Number is required" />
                                                        ),
                                                    },
                                                    maxLength: {
                                                        value: 30,
                                                        message: (
                                                            <FormattedMessage
                                                                id="value_max_30"
                                                                defaultMessage="Mobile Number should not exceed 30 characters"
                                                            />
                                                        ),
                                                    },
                                                    minLength: {
                                                        value: 2,
                                                        message: (
                                                            <FormattedMessage
                                                                id="value_min_2"
                                                                defaultMessage="Mobile Number should more than 2 characters"
                                                            />
                                                        ),
                                                    },
                                                })}
                                            />
                                            {/* <small className="form-text text-muted float-right">
                                                <Link to="/resend-verification">No receive verification email?</Link>
                                            </small> */}
                                            {errors.username == null ? (
                                                ""
                                            ) : (
                                                <div className="invalid-feedback">{errors.username.message}</div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-password">
                                                <FormattedMessage id="psw" defaultMessage="Password" />
                                            </label>
                                            <input
                                                className={`form-control ${errors.password == null ? "" : "is-invalid"}`}
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="on"
                                                placeholder={intl.formatMessage({ id: "psw", defaultMessage: "Enter Password" })}
                                                {...register("password", {
                                                    required: {
                                                        value: true,
                                                        message: (
                                                            <FormattedMessage id="field_required" defaultMessage="Password is required" />
                                                        ),
                                                    },
                                                    // minLength: {
                                                    //     value: 8,
                                                    //     message: "Password should more than 8 characters",
                                                    // },
                                                })}
                                            />
                                            <small className="form-text text-muted float-right">
                                                <Link to="/forgot-password">
                                                    <FormattedMessage id="forgot_password" defaultMessage="Forgot password" />?
                                                </Link>
                                            </small>
                                            {errors.password == null ? (
                                                ""
                                            ) : (
                                                <div className="invalid-feedback">{errors.password.message}</div>
                                            )}
                                        </div>
                                        {errorMsg ? (
                                            <div>
                                                <small className="text-danger">{errorMsg}</small>
                                            </div>
                                        ) : null}

                                        <button
                                            type="submit"
                                            className={
                                                apiLoading
                                                    ? "btn btn-primary btn-loading mt-2 mt-md-3 mt-lg-4 mobile-bt"
                                                    : "btn btn-primary mt-2 mt-md-3 mt-lg-4 mobile-btn"
                                            }
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                            <FormattedMessage id="login" defaultMessage="Login" />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0 justify-content-center d-md-down-none">
                                <div className="card-body card-body-register">
                                    <h3 className="card-title">
                                        <FormattedMessage id="register_stat_1" defaultMessage="Don't Have An Account?" />
                                    </h3>
                                    <p>
                                        <FormattedMessage id="register_stat_2" defaultMessage="Sign up for a free account at our store." />{" "}
                                        <br />
                                        <FormattedMessage
                                            id="register_stat_3"
                                            defaultMessage="Registration is quick and easy. Register now."
                                        />
                                    </p>
                                    <div className="justify-around mt-5">
                                        <Link to="/register" className="btn btn-primary mr-3">
                                            <FormattedMessage id="register_stat_4" defaultMessage="Create New Account" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
