import { STORE_GENERAL_INFO, STORE_COUNTRIES, STORE_SYSTEM_INFO, STORE_GROUP_CATEGORY } from "./generalInfoTypes";

// eslint-disable-next-line import/prefer-default-export
export function storeGeneralInfo(generalInfo) {
    return {
        type: STORE_GENERAL_INFO,
        generalInfo,
    };
}

export function storeSystemInfo(systemInfo) {
    return {
        type: STORE_SYSTEM_INFO,
        systemInfo,
    };
}

export function storeCountries(countries) {
    return {
        type: STORE_COUNTRIES,
        countries,
    };
}
export function storeGroupCategory(categories) {
    return {
        type: STORE_GROUP_CATEGORY,
        categories,
    };
}
