// react
import React, { useEffect, useState } from "react";

// third-party
import moment from "moment";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";

const logo = "images/logos/logo.png";

export default function Voucher(props) {
    const { voucher, onClick, layout, wantRedeem, closeModalCount, type } = props;
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        if (closeModalCount > 0) {
            setModalVisible(false);
        }
    }, [closeModalCount]);

    const renderVoucherModal = () => {
        return (
            <Modal isOpen={modalVisible} toggle={() => setModalVisible(false)} centered>
                <ModalHeader toggle={() => setModalVisible(false)}>
                    <FormattedMessage id="voucher_detail" defaultMessage="Voucher Details" />
                </ModalHeader>
                <ModalBody className="px-4" style={{ maxHeight: "80vh", overflowX: "auto" }}>
                    <div className="text-center">
                        <img
                            src={voucher.vc_image ? voucher.vc_image : logo}
                            style={{ width: 200, height: 200, objectFit: "contain", marginBottom: 10 }}
                            onError={(e) => (e.target.src = logo)}
                            alt="voucher-logo"
                        />
                        <h3 m>{voucher.vc_name}</h3>
                    </div>
                    <div className="px-1 px-md-3">
                        <div className="pt-4">
                            <div className="row">
                                <div className="col-5 text-left">
                                    <b>
                                        <FormattedMessage id="voucher_no" defaultMessage="No" />
                                    </b>
                                </div>
                                <div className="col-6 text-left">: {voucher.vm_voucher_no ? voucher.vm_voucher_no : voucher.vc_no}</div>
                            </div>
                            <div className="row">
                                <div className="col-5 text-left">
                                    <b>
                                        <FormattedMessage id="expiry_date" defaultMessage="Expiry Date" />
                                    </b>
                                </div>
                                <div className="col-6 text-left">: {moment(voucher.vc_expire_date).format("DD/MM/YYYY")}</div>
                            </div>
                            <div className="row">
                                <div className="col-5 text-left">
                                    <b>
                                        <FormattedMessage id="disc_amt" defaultMessage="Discount Amount" />
                                    </b>
                                </div>
                                <div className="col-6 text-left">: {voucher.vc_amount}</div>
                            </div>
                            {voucher.vc_redeem_formula ? (
                                <div className="row">
                                    <div className="col-5 text-left">
                                        <b>
                                            <FormattedMessage id="redeem_points" defaultMessage="Redeem Points" />
                                        </b>
                                    </div>
                                    <div className="col-6 text-left">: {voucher.vc_redeem_formula}</div>
                                </div>
                            ) : null}
                        </div>
                        <hr />
                        <div>
                            <b>
                                <FormattedMessage id="tnc_full" defaultMessage="Terms & Condition" />
                            </b>
                        </div>
                        <p className="text-muted">{voucher.term_condition ?? voucher.vc_tnc ?? "-"}</p>
                        {voucher.vc_redeem_formula ? (
                            <div>
                                <button className="btn btn-primary w-100" onClick={() => wantRedeem(voucher)}>
                                    <FormattedMessage id="redeem" defaultMessage="Redeem" />
                                </button>
                            </div>
                        ) : null}
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    return (
        <div className={"col-12 col-sm-12" + (layout !== "full-width" ? " col-md-6" : "")}>
            {renderVoucherModal()}
            <div
                className={type == "2" ? "voucher-box inactive" : "voucher-box"}
                onClick={() => (type == "2" ? {} : onClick ? onClick(voucher) : setModalVisible(true))}
            >
                <div className="left">
                    <img src={voucher.vc_image ? voucher.vc_image : logo} onError={(e) => (e.target.src = logo)} alt="voucher-logo" />
                </div>
                <div className="right">
                    <div>
                        <div className="name">{voucher.vm_voucher_no}</div>
                        {type == "reward" ? (
                            <div className="name">{voucher.vc_name}</div>
                        ) : (
                            <>
                                <FormattedMessage id="valid_until" defaultMessage="Valid until" />{" "}
                                {moment(voucher.vc_expire_date).format("DD/MM/YYYY")}
                            </>
                        )}
                        <div> {voucher.vc_amount}</div>
                        <div style={{ fontSize: 15 }}>
                            {voucher.vc_redeem_formula ? <b style={{ fontSize: 15 }}>{voucher.vc_redeem_formula}</b> : null}
                        </div>
                    </div>
                    <div
                        className="tnc"
                        onClick={(event) => {
                            event.stopPropagation();
                            setModalVisible(true);
                        }}
                    >
                        <FormattedMessage id="tnc" defaultMessage="T&C" />
                    </div>
                </div>
            </div>
        </div>
    );
}
