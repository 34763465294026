// react
import React, { useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import { connect, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

// application
import MobileLinks from "./MobileLinks";
import { ArrowRoundedRight8x13Svg, Cross20Svg } from "../../svg";
import { currencyChange } from "../../store/currency";
import { changeUserLanguage } from "../../store/locale";
import { mobileMenuClose } from "../../store/mobile-menu";

// data stubs
import currencies from "../../data/shopCurrencies";
import { url } from "../../services/utils";
import { languages } from "../../data/generalData";
import { openDeliveryModal } from "../../store/user";
import { getMemberCode } from "../../api/auth";

function MobileMenu(props) {
    const { mobileMenuState, closeMobileMenu, changeUserLanguage, changeCurrency, categories, locale, openDeliveryModal } = props;
    const [mobileMenu, setMobileMenu] = useState([]);
    const [openSubmenu, setOpenSubmenu] = useState({ type: 0, open: false }); // 0 = delivery method , 1 = languange
    let deliveryMethod = useSelector((state) => state?.user?.deliveryMethod);

    const classes = classNames("mobilemenu", {
        "mobilemenu--open": mobileMenuState.sideMenuOpen,
    });

    const setMenuItem = (item, count) => {
        let countLayer = 1; //limit loop up to 4 layer
        countLayer++;
        count = count ? count : 1;

        return {
            type: "link",
            label: item.group_name ? item.group_name : item.line_name,
            url: url.category(item),
            children: item.line?.length > 0 && count <= 4 ? item.line.map((childItem) => setMenuItem(childItem, countLayer)) : [],
        };
    };

    useEffect(() => {
        let menu = mobileMenu;

        menu.push(
            {
                type: "link",
                label: <FormattedMessage id="topbar.aboutUs" defaultMessage="About Us" />,
                url: "/about-us",
                children: [],
            },
            {
                type: "link",
                label: <FormattedMessage id="shop" defaultMessage="Products" />,
                url: "/category",
                children: [],
            },
            // {
            //     type: "link",
            //     label: <FormattedMessage id="membership" defaultMessage="Membership" />,
            //     url: "/",
            //     children: [],
            // },
            {
                type: "link",
                label: <FormattedMessage id="footer.contactUs" defaultMessage="Contact Us" />,
                url: "/outlets",
                children: [],
            }
        );
        setMobileMenu(menu);
    }, []);

    const handleItemClick = (item) => {
        if (item.data) {
            if (item.data.type === "language") {
                changeUserLanguage(item.data.code);
                closeMobileMenu();
            }
            if (item.data.type === "currency") {
                const currency = currencies.find((x) => x.currency.code === item.data.code);

                if (currency) {
                    changeCurrency(currency.currency);
                    closeMobileMenu();
                }
            }
        }
        if (item.type === "link") {
            closeMobileMenu();
        }
    };

    const shopOption = (title) => (
        <div className="section">
            <div className="section-title mb-2">{title}</div>
            {languages.map((item, index) => {
                return (
                    <li
                        key={item.code}
                        onClick={() => {
                            changeUserLanguage(item.code);
                            setOpenSubmenu({ type: 1, open: false });
                        }}
                        className="select-item"
                    >
                        <img src={item.icon} className="mr-2" alt={item.code} />
                        {item.title}
                    </li>
                );
            })}
        </div>
    );

    const language = languages.find((x) => x.code === locale);

    return (
        <>
            <div className={classes}>
                {/* eslint-disable-next-line max-len */}
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
                <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
                <div className="mobilemenu__body">
                    {/* <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Menu</div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div> */}
                    <div className="mobilemenu__content">
                        {window.location.pathname !== "/checkout" && getMemberCode() ? (
                            <>
                                <div className="section pt-4">
                                    <div className="section-title">
                                        <FormattedMessage id="select_delivery_mtd" defaultMessage="Select Delivery Method" />
                                    </div>
                                    <div
                                        className="method-indicator"
                                        onClick={() => {
                                            openDeliveryModal(true);
                                            closeMobileMenu();
                                        }}
                                    >
                                        <div>
                                            {deliveryMethod.type == "DELIVERY" ? (
                                                <FormattedMessage id="delivery" defaultMessage="DELIVERY" />
                                            ) : (
                                                <FormattedMessage id="pickup" defaultMessage="PICKUP" />
                                            )}
                                        </div>
                                        <div className="d-flex">
                                            <div className="right-icon2">
                                                <ArrowRoundedRight8x13Svg />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </>
                        ) : null}
                        <div className="section pt-1">
                            <div className="section-title">
                                <FormattedMessage id="select_language" defaultMessage="Select Language" />
                            </div>
                            <div className="method-indicator" onClick={() => setOpenSubmenu({ type: 1, open: true })}>
                                <div>{language.code}</div>
                                <div className="d-flex">
                                    <div className="right-icon2">
                                        <ArrowRoundedRight8x13Svg />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="section">
                            <div className="section-title">
                                <FormattedMessage id="menu" defaultMessage="Menu" />
                            </div>
                            <MobileLinks links={mobileMenu} onItemClick={handleItemClick} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={openSubmenu.open ? "mobile-submenu open" : "mobile-submenu"}>
                <div className="mobile-submenu__backdrop" onClick={() => setOpenSubmenu({ type: 0, open: false })} />
                <div className="mobilemenu__body">
                    <div className="mobilemenu__content">
                        {openSubmenu.type == 1
                            ? shopOption(<FormattedMessage id="select_language" defaultMessage="Select Language" />)
                            : null}
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
    locale: state.locale,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeUserLanguage,
    changeCurrency: currencyChange,
    openDeliveryModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
