import { FormattedMessage, useIntl } from 'react-intl'

export default [
    {
        id: 1,
        name : <FormattedMessage id='my_account' defaultMessage="My Account"/>,
        url: "/account/profile",
    },
    {
        id: 2,
        name : <FormattedMessage id='addresses' defaultMessage="Addresses"/>,
        url: "/account/addresses",
    },
    {
        id: 3,
        name : <FormattedMessage id='order_history' defaultMessage="Order History"/>,
        url: "/account/orders",
    },
    {
        id: 4,
        name : <FormattedMessage id='my_voucher' defaultMessage="My Vouchers"/>,
        url: "/account/vouchers",
    },
    {
        id: 5,
        name : <FormattedMessage id='my_feed' defaultMessage="My Feeds"/>,
        url: "/account/feeds",
    },
    // {
    //     id: 5,
    //     name: "Rewards history",
    //     url: "/account/rewards-history",
    // },
];
