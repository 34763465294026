// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default function FooterLinks(props) {
    const { title, items, socialInfo } = props;
    let socialLinks = {
        facebook_url: "https://www.facebook.com/hupsoonfoodseremban",
        tiktok_url: "https://www.tiktok.com/@hup_soon_food",
        instagram_url: "https://www.instagram.com/hupsoonfood/",
        youtube_url: "https://www.youtube.com/@hupsoonfood",
    };

    const linksList = items.map((item, index) => (
        <li key={index} className="footer-links__item">
            <a href={item.url} target={item.external ? "_blank" : "_self"} rel="noopener noreferrer" className="footer-links__link">
                {item.title}
            </a>
        </li>
    ));

    return (
        <div className="site-footer__widget footer-links">
            <h5 className="footer-links__title">{title}</h5>
            <ul className="footer-links__list">{linksList}</ul>
            <br />
            {socialInfo?.facebook_url ? (
                <>
                    <h5 className="footer-links__title mb-2">
                        <FormattedMessage id="footer.social" defaultMessage="Join Us On Social" />
                    </h5>
                    <div className="site-footer__social-icons">
                        <ul className="social-icons-list form-row mb-0">
                            {socialInfo?.facebook_url ? (
                                <li className="social-icons">
                                    <a href={socialInfo?.facebook_url} target="_blank">
                                        <i className="fab fa-facebook-f social-icon" aria-hidden="true"></i>
                                    </a>
                                </li>
                            ) : null}

                            {socialInfo?.website_URL ? (
                                <li className="social-icons">
                                    <a href={socialInfo?.website_URL} target="_blank">
                                        <i className="fab fa-youtube social-icon" aria-hidden="true"></i>
                                    </a>
                                </li>
                            ) : null}

                            {socialInfo?.tiktok_url ? (
                                <li className="social-icons">
                                    <a href={socialInfo?.tiktok_url} target="_blank">
                                        <i className="fab fa-tiktok social-icon" aria-hidden="true"></i>
                                    </a>
                                </li>
                            ) : null}

                            {socialInfo?.instagram_url ? (
                                <li className="social-icons">
                                    <a href={socialInfo?.instagram_url} target="_blank">
                                        <i className="fab fa-instagram social-icon" aria-hidden="true"></i>
                                    </a>
                                </li>
                            ) : null}
                        </ul>
                    </div>
                </>
            ) : null}
        </div>
    );
}

FooterLinks.propTypes = {
    /** widget title */
    title: PropTypes.node.isRequired,
    /** array of links */
    items: PropTypes.array,
};

FooterLinks.defaultProps = {
    items: [],
};
