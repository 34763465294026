import { element } from "prop-types";
import { MOBILE_MENU_CLOSE, MOBILE_MENU_OPEN, MOBILE_USERMENU_OPEN, MOBILE_USERMENU_CLOSE } from "./mobileMenuActionTypes";

const initialState = {
  sideMenuOpen: false,
  userMenuOpen: false,
};

export default function mobileMenuReducer(state = initialState, action) {
  switch (action.type) {
    case MOBILE_MENU_OPEN:
      return {
        ...state,
        sideMenuOpen: true,
      };
    case MOBILE_MENU_CLOSE:
      return {
        ...state,
        sideMenuOpen: false,
      };

    case MOBILE_USERMENU_OPEN:
      document.body.style.overflowY = "hidden";
      return {
        ...state,
        userMenuOpen: true,
      };
    case MOBILE_USERMENU_CLOSE:
      document.body.style.overflowY = "scroll";
      return {
        ...state,
        userMenuOpen: false,
      };
    default:
      return state;
  }
}
