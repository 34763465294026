// react
import React, { useEffect, Suspense, lazy } from "react";

// third-party
import classNames from "classnames";
import { Link, matchPath, Redirect, Switch, Route, useHistory } from "react-router-dom";
import { FormattedMessage } from 'react-intl'

// application
import PageHeader from "../shared/PageHeader";
import { getMemberCode, logout } from "../../api/auth";
import { toast } from "react-toastify";
import BlockLoader from "../blocks/BlockLoader";

// pages
const AccountPageAddresses = lazy(() => import("./AccountPageAddresses"));
const AccountPageEditAddress = lazy(() => import("./AccountPageEditAddress"));
const AccountPageOrders = lazy(() => import("./AccountPageOrders"));
const AccountPagePassword = lazy(() => import("./AccountPagePassword"));
const AccountPageProfile = lazy(() => import("./AccountPageProfile"));
const AccountPageOrderDetails = lazy(() => import("./AccountPageOrderDetails"));
const AccountPageVoucher = lazy(() => import("./AccountPageVoucher"));
const AccountNotification = lazy(() => import("./AccountNotification"));
const AccountPagePurchaseHistory = lazy(() => import("./AccountPagePurchaseHistory"));
const AccountPagePurchaseDetails = lazy(() => import("./AccountPagePurchaseDetails"));
const AccountPageRewards = lazy(() => import("./AccountPageRewards"));
const AccountPageFeedback = lazy(() => import("./AccountPageFeedback"));
const AccountPagePointsHistory = lazy(() => import("./AccountPagePointsHistory"));
const AccountPagePointsHistoryDetails = lazy(() => import("./AccountPagePointsHistoryDetails"));

export default function AccountLayout(props) {
    const { match, location } = props;
    const history = useHistory();

    const handleLogout = () => {
        toast.info(<FormattedMessage id="logout_success_toast" defaultMessage="Logout Successful" />, { autoClose: 2000 });
        logout(history);
    };

    useEffect(() => {
        if (document.getElementById("site-body")) {
            document.getElementById("site-body").classList.add("grey");
            return function cleanup() {
                document.getElementById("site-body").classList.remove("grey");
            };
        }
    }, []);

    const links = [
        { title: <FormattedMessage id='user_profile' defaultMessage="User Profile" />, url: "profile" },
        { title: <FormattedMessage id='addresses' defaultMessage="Addresses" />, url: "addresses" },
        { title: <FormattedMessage id='chg_password' defaultMessage="Change Password" />, url: "password" },
        { title: <FormattedMessage id='order_history' defaultMessage="Order History" />, url: "orders" },
        { title: <FormattedMessage id='my_voucher' defaultMessage="My Vouchers" />, url: "vouchers" },
        { title: <FormattedMessage id='my_feed' defaultMessage="My Feeds" />, url: "feeds" },
        { title: <FormattedMessage id='purchase_history' defaultMessage="Outlet Purchases" />, url: "purchase-history" },
        { title: <FormattedMessage id='my_rewards' defaultMessage="My Rewards" />, url: "my-rewards" },
        { title: <FormattedMessage id='feedback' defaultMessage="Feedback" />, url: "add-feedback" },
        { title: <FormattedMessage id="logout" defaultMessage="Log Out" />, url: "login" },
    ].map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url });
        const classes = classNames("account-nav__item", {
            "account-nav__item--active": isActive,
        });

        return (
            <li key={link.url} className={classes}>
                {link.url == "login" ? (
                    <Link to={window.location.pathname} onClick={handleLogout}>
                        {link.title}
                    </Link>
                ) : (
                    <Link to={url}>{link.title}</Link>
                )}
            </li>
        );
    });

    if (!getMemberCode()) {
        return <Redirect to={`/`} />;
    }

    return (
        <React.Fragment>
            <PageHeader header={<FormattedMessage id='my_account' defaultMessage="My Account" />} breadcrumb={[]} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3 d-flex">
                            <div className="account-nav flex-grow-1">
                                <h4 className="account-nav__title"><FormattedMessage id='navigation' defaultMessage="Navigation" /></h4>
                                <ul>{links}</ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Suspense
                                fallback={
                                    <div className="block-empty-page">
                                        <BlockLoader />
                                    </div>
                                }
                            >
                                <Switch>
                                    <Route exact path={`${match.path}/profile`} component={AccountPageProfile} />
                                    <Route exact path={`${match.path}/addresses/:addressID`} component={AccountPageEditAddress} />
                                    <Route exact path={`${match.path}/addresses`} component={AccountPageAddresses} />
                                    <Route exact path={`${match.path}/password`} component={AccountPagePassword} />
                                    <Route exact path={`${match.path}/feeds`} component={AccountNotification} />
                                    <Route exact path={`${match.path}/vouchers`} component={AccountPageVoucher} />

                                    <Route exact path={`${match.path}/orders`} component={AccountPageOrders} />
                                    <Route exact path={`${match.path}/orders/:orderNo`} component={AccountPageOrderDetails} />
                                    <Route exact path={`${match.path}/purchase-history`} component={AccountPagePurchaseHistory} />
                                    <Route exact path={`${match.path}/purchase-history/:transno`} component={AccountPagePurchaseDetails} />
                                    <Route exact path={`${match.path}/my-rewards/membership-points-history`} component={AccountPagePointsHistory} />
                                    <Route exact path={`${match.path}/my-rewards/membership-points-history/:transno`} component={AccountPagePointsHistoryDetails} />
                                    <Route exact path={`${match.path}/my-rewards`} component={AccountPageRewards} />
                                    <Route exact path={`${match.path}/add-feedback`} component={AccountPageFeedback} />
                                    <Redirect from={match.path} to={`${match.path}/profile`} />
                                </Switch>
                            </Suspense>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
