// react
import React from "react";

// application
import { NoSearchResultSvg } from "../../svg";

export default function BlockNoResult(props) {
    const { message, textSize } = props;
    return (
        <div className="block-no-result w-100">
            <NoSearchResultSvg />
            <div className={"block-no-result-title" + (textSize ? " " + textSize : "")}>{message}</div>
        </div>
    );
}
