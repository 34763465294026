import { getHeader, paramBuilder } from "../services/utils";
import store from "../store";
import { getMemberCode } from "./auth";
const axios = require("axios");

export async function getProductCategories(params) {
    // params.sort_by = "created_at";
    // params.sort_type = "asc";
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/productline_listing" + queryString;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                withCredentials: true,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getProductGroupCategories(params) {
    // params.sort_by = "created_at";
    // params.sort_type = "asc";
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/get_prdtgroupline_listing" + queryString;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                withCredentials: true,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

// Params:
// pickupstore = pass pickup store/delivery store code
export async function getProductDetails(code, params) {
    let passParams = params ? params : {};
    passParams.client = "hupsoon";
    const memberCode = getMemberCode();
    let selectedPickUpStore = store.getState().user.deliveryMethod?.store?.store_code;
    if (selectedPickUpStore && memberCode) passParams.pickupstore = selectedPickUpStore;
    const queryString = paramBuilder(passParams);
    let url =
        process.env.REACT_APP_API_URL +
        process.env.REACT_APP_API_PREFIX +
        "/product_detail/" +
        code +
        (memberCode ? "/" + memberCode : "") +
        queryString;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


// Params:
// customer_code : some clients' business logic is that different types of members show different price.So in this case they need to pass customer code to check if this member falls in what membership type and return the correct price. If without login, no need to pass.
// search: wild search for product name
// category: Product Category Code.
// model: Product Model Code
// line : product line code
// type: available input: bestseller/homepage
// limit: limit number of records to retrieve. Data format: {start_record},{number_record}”. Example: 0,20 (to display first 20 records)
// group= pass group code (prdt_group)
// store = pass pickup store/delivery store no
export async function getProductList(params) {
    const memberCode = getMemberCode();
    let passParams = params ? params : {};
    passParams.client = "hupsoon";
    let selectedPickUpStore = store.getState().user.deliveryMethod?.store?.store_no;
    if (selectedPickUpStore && memberCode) passParams.store = selectedPickUpStore;
    if (memberCode) passParams.customer_code = memberCode;

    const queryString = paramBuilder(passParams);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/product_listing" + queryString;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getProductReview(code, params) {
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/get_review/" + code + queryString;

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function addProductFavourite(data) {
    const memberCode = getMemberCode();
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/add_favorite/" + memberCode;

    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                headers: getHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
