import { UPDATE_USER, UPDATE_NOTIFICATION, UPDATE_DELIVERY_METHOD, OPEN_DELIVERY_MODAL, SET_USER_ADDRESS } from "./userActionTypes";
import { removeCart } from "../../api/cart";
const initialState = {
    profile: null,
    notification: 0,
    deliveryMethod:
        localStorage.getItem("shipping") && JSON.parse(localStorage.getItem("shipping"))
            ? JSON.parse(localStorage.getItem("shipping"))
            : { type: null },
    deliveryMethodModal: false,
};

export default function userReducer(state = initialState, action) {
    if (action.type === UPDATE_USER) {
        return { ...state, profile: action.profile };
    } else if (action.type === UPDATE_NOTIFICATION) {
        if (action.updateMode == "update") return { ...state, notification: state.notification + action.notification };
        else return { ...state, notification: action.notification };
    } else if (action.type === UPDATE_DELIVERY_METHOD) {
        //clear cart if delivery method change or pick up store has change
        if (
            state.deliveryMethod?.type !== action.method?.type ||
            state.deliveryMethod?.store?.store_code !== action.method?.store?.store_code
        ) {
            removeCart(null);
        }
        localStorage.setItem("shipping", JSON.stringify({ ...state.deliveryMethod, ...action.method }));
        return { ...state, deliveryMethod: { ...state.deliveryMethod, ...action.method } };
    } else if (action.type === OPEN_DELIVERY_MODAL) {
        return { ...state, deliveryMethodModal: action.visible };
    } else if (action.type === SET_USER_ADDRESS) {
        localStorage.setItem("shipping", JSON.stringify({ ...state.deliveryMethod, address: action.address }));
        return { ...state, deliveryMethod: { ...state.deliveryMethod, address: action.address } };
    }
    return state;
}
