// react
import React, { useState, useEffect } from "react";

// third-party
import { Modal, ModalBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import { getMemberCode } from "../../api/auth";
import { FormattedMessage } from "react-intl";
// application
import { ArrowRoundedLeft8x13Svg, Cross20Svg, DeliveriesSvg } from "../../svg";
import { capitalizeFirstLetter, convertAddressObjectToString, handleDisableDays, showGeneralError, sortDefaultAddress } from "../../services/utils";
import { useSelector, useDispatch } from "react-redux";
import { updateDeliveryMethod, openDeliveryModal, setUserAddress } from "../../store/user";
import { DELIVERY_METHOD, DELIVERY_N_PICKUP, PICKUP_METHOD } from "../../data/generalData";
import { getPickUpBufferDay, getPickUpStoreList, getPickUTimeRange, getStoreByDistance } from "../../api/pickUpStore";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import { addAddress, getAddressList } from "../../api/user";
import { convertAddressToLatLong } from "../../api/general";
import BlockNoResult from "../blocks/BlockNoResult";
import AddressForm from "./AddressForm";

function DeliveryMethodModal() {
    const dispatch = useDispatch();
    const [firstLoad, setFirstLoad] = useState(true);
    const memberCode = getMemberCode();
    const [selectedStore, setSelectedStore] = useState(null);
    const [pickUpMode, setPickUpMode] = useState(0);
    const [storeList, setStoreList] = useState([]);
    const [availableStoreList, setAvailableStoreList] = useState([]);
    const [pickUpBufferDay, setPickUpBufferDay] = useState(1);
    const [pickUpTimeRange, setPickUpTimeRange] = useState({});
    const [selectedDate, setSelectedDate] = useState("");
    const [locationType, setLocationType] = useState(0);
    const [defaultAddress, setDefaultAddress] = useState("");
    const [apiLoading, setApiLoading] = useState(false);
    const [doneLoad, setDoneLoad] = useState(false);
    const [createAddress, setCreateAddress] = useState(false);
    const [addressList, setAddressList] = useState([]);

    let modalVisible = useSelector((state) => state?.user?.deliveryMethodModal);
    let deliveryMethod = useSelector((state) => state?.user?.deliveryMethod);
    const token = getMemberCode();

    useEffect(() => {
        if (deliveryMethod.address && deliveryMethod.address?.add_state) setLocationType(deliveryMethod.address);
        getPickUpStoreList(/* { limit: "0,10" } */).then((res) => {
            setStoreList(res.filter((item) => item.store_lat_long !== ""));
        });
    }, []);

    useEffect(() => {
        if (deliveryMethod?.type == PICKUP_METHOD && modalVisible) {
            //the delivery method date time is expired or not
            if (moment().isAfter(deliveryMethod.date + " " + deliveryMethod.time)) {
                setSelectedStore(deliveryMethod.store);
                setPickUpMode(3);
            }
        }
    }, [modalVisible]);

    useEffect(() => {
        if (token || modalVisible) {
            getAddressList().then((res) => {
                if (!res.Error) {
                    setAddressList(res.sort(sortDefaultAddress));
                }
                setDoneLoad(true);
            });
        }
    }, [token,modalVisible]);

    useEffect(() => {
        // getDefaultAddress().then((res) => {
        //     if (!res.Error) {
        //         setDefaultAddress(res);
        //     }
        //     setDoneLoad(true);
        // });
        if (pickUpMode == 2 && !pickUpTimeRange?.pickup_min_time_range) {
            getPickUpBufferDay().then((res) => {
                setPickUpBufferDay(res.pickup_min_time);
            });
            getPickUTimeRange().then((res) => {
                // example payload:
                // {
                //     "pickup_min_time_range":"00:00",
                //     “Pickup_max_time_range":"23:59",
                //     “pickup_days_range": "0,0,1,1,1,1,1",
                //     "error_status":"0"
                //   }
                setPickUpTimeRange(res);
            });
        }
    }, [pickUpMode]);

    const closeModal = () => {
        dispatch(openDeliveryModal(false));
        setFirstLoad(false);
        setTimeout(() => {
            setPickUpMode(0);
            setPickUpTimeRange({});
            setPickUpBufferDay(1);
        }, 500);
    };

    const handleChangeType = (type) => {
        if (type == DELIVERY_METHOD) {
            dispatch(updateDeliveryMethod({ type: type, store: selectedStore }));
            closeModal();
        } else {
            setPickUpMode(3);
        }
    };

    const confirmPickUpSetting = () => {
        if (selectedDate && selectedDate[0]) {
            dispatch(
                updateDeliveryMethod({
                    type: PICKUP_METHOD,
                    store: selectedStore,
                    date: moment(selectedDate[0]).format("YYYY-MM-DD"),
                    time: moment(selectedDate[0]).format("HH:mm"),
                })
            );
            setPickUpMode(0);
            closeModal();
        } else {
            toast.error("Please select preferred date and time for self pick up");
        }
    };

    const backToPrevious = (
        <div className="pl-3 d-flex align-items-center c-pointer" onClick={() => setPickUpMode(pickUpMode - 1)}>
            <ArrowRoundedLeft8x13Svg className="page-link__arrow page-link__arrow--left mr-2" aria-hidden="true" />{" "}
            <b>
                <FormattedMessage id="back" defaultMessage="Back" />
            </b>
        </div>
    );

    const closeButton =
        !firstLoad || deliveryMethod.type ? (
            <span className="modal-close-btn" onClick={() => closeModal()}>
                <Cross20Svg />
            </span>
        ) : null;

    function handleCurrentLocation() {
        if (locationType == "gps") {
            setApiLoading(true);

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        getNearestStores({ latitude: position.coords?.latitude, longitude: position.coords?.longitude });
                    },
                    (err) => {
                        console.log(err);
                    }
                );
            } else {
                toast.error("Geolocation is not supported by this browser.");
            }
        } else if (locationType == "new_address") {
            setCreateAddress(true);
        } else {
            setApiLoading(true);
            dispatch(setUserAddress(locationType));
            convertAddressToLatLong(convertAddressObjectToString(locationType))
                .then((res) => {
                    let position = res?.results[0]?.geometry?.location;
                    getNearestStores({ latitude: position?.lat, longitude: position?.lng });
                })
                .catch((err) => {
                    console.log(err);
                    showGeneralError(err);
                });
        }
    }

    function getNearestStores(position) {
        let stores = [];
        if (storeList.length > 0) {
            storeList?.forEach((item) => {
                let latlong = item.store_lat_long.split(",");
                stores.push({ storecode: item.store_code, latitude: latlong[0], longitude: latlong[1] ? latlong[1]?.trim() : "" });
            });
        }

        let params = {
            customer_address: [
                {
                    latitude: position?.latitude,
                    longitude: position?.longitude,
                },
            ],
            outlet: stores,
        };
        getStoreByDistance(params).then((res) => {
            setApiLoading(false);
            setAvailableStoreList(res);
            setPickUpMode(1);
        });
    }

    const handleSelectStore = (item) => {
        setSelectedStore(item);
        setPickUpMode(2);
    };

    const storeAddress = (data) => {
        addAddress([data])
            .then((res) => {
                toast.success(<FormattedMessage id="add_address_success_toast" defaultMessage="Address has been added" />, {
                    autoClose: 3000,
                });
                setDefaultAddress(data);
                setCreateAddress(false);
            })
            .catch((err) => {
                setApiLoading(false);
                showGeneralError(err);
            });
    };

    const renderSelectLocationSection = () => {
        return createAddress ? (
            <div className="text-left">
                <div className="d-flex flex-wrap align-items-center">
                    <div className="pl-3 d-flex align-items-center c-pointer" onClick={() => setCreateAddress(false)}>
                        <div>
                            <ArrowRoundedLeft8x13Svg className="page-link__arrow page-link__arrow--left mr-3" aria-hidden="true" />
                        </div>
                        <h3 className="mb-0">
                            <FormattedMessage id="create_new_address" defaultMessage="Create new address" />
                        </h3>
                    </div>
                </div>

                <br />
                <AddressForm submitAddress={(data) => storeAddress(data)} />
            </div>
        ) : (
            <div className="py-3 pt-4">
                {/* {backToPrevious} */}
                {closeButton}
                <h4>
                    <FormattedMessage id="delivery_method_modal_pickup_1" defaultMessage="Select Your Store Location" />
                </h4>
                <div className="svg-icon my-3">
                    <DeliveriesSvg />
                </div>
                <b>
                    <FormattedMessage
                        id="delivery_method_modal_pickup_1_subtitle"
                        defaultMessage="*Choose address to fetch stores within 30KM"
                    />
                </b>
                <div className="py-3 row justify-content-center">
                    <div className="payment-methods mb-0 col-12 col-sm-12 col-md-10 col-lg-9" style={{ maxHeight: 290, overflow: "auto" }}>
                        <ul className="payment-methods__list">
                            {/* {defaultAddress ? (
                                <li className="payment-methods__item mb-3">
                                    <label className="payment-methods__item-header">
                                        <span className="payment-methods__item-radio input-radio">
                                            <span className="input-radio__body">
                                                <input
                                                    type="radio"
                                                    className="input-radio__input"
                                                    name="checkout_payment_method"
                                                    checked={locationType == "default_address"}
                                                    onChange={(e) => {
                                                        setLocationType("default_address");
                                                    }}
                                                />
                                                <span className="input-radio__circle" />
                                            </span>
                                        </span>
                                        <span className="payment-methods__item-title text-left">
                                            <FormattedMessage id="your_default_address" defaultMessage="Your Default Address" /> :<br />
                                            {defaultAddress?.add_address1 +
                                                " " +
                                                defaultAddress?.add_address2 +
                                                ", " +
                                                defaultAddress?.add_postcode +
                                                ", " +
                                                defaultAddress?.add_city +
                                                ", " +
                                                (defaultAddress.add_state
                                                    ? defaultAddress.add_state.charAt(0) + defaultAddress.add_state.toLowerCase().slice(1)
                                                    : "")}
                                        </span>
                                    </label>
                                </li>
                            ) : (
                                <li className="payment-methods__item mb-3">
                                    <label className="payment-methods__item-header">
                                        <span className="payment-methods__item-radio input-radio">
                                            <span className="input-radio__body">
                                                <input
                                                    type="radio"
                                                    className="input-radio__input"
                                                    name="checkout_payment_method"
                                                    checked={locationType == "new_address"}
                                                    onChange={(e) => {
                                                        setLocationType("new_address");
                                                    }}
                                                />
                                                <span className="input-radio__circle" />
                                            </span>
                                        </span>
                                        <FormattedMessage id="create_new_address" defaultMessage="Create new address" />
                                    </label>
                                </li>
                            )} */}

                            {addressList.length > 0 ? (
                                addressList?.map((item) => {
                                    return (
                                        <li key={item?.add_id} className="payment-methods__item mb-3">
                                            <label className="payment-methods__item-header">
                                                <span className="payment-methods__item-radio input-radio">
                                                    <span className="input-radio__body">
                                                        <input
                                                            type="radio"
                                                            className="input-radio__input"
                                                            name="checkout_payment_method"
                                                            checked={locationType?.add_id == item.add_id}
                                                            onChange={(e) => {
                                                                setLocationType(item);
                                                            }}
                                                        />
                                                        <span className="input-radio__circle" />
                                                    </span>
                                                </span>
                                                <span className="text-left">
                                                    {item.add_person}
                                                    {item?.add_default == 1 ? (
                                                        <>
                                                            {" ["}
                                                            <FormattedMessage id="default" defaultMessage="default" />
                                                            {"] "}
                                                        </>
                                                    ) : null}
                                                    <div className="text-muted" style={{ fontSize: 14 }}>
                                                        {item?.add_address1 +
                                                            " " +
                                                            item?.add_address2 +
                                                            ", " +
                                                            item?.add_postcode +
                                                            ", " +
                                                            item?.add_city +
                                                            ", " +
                                                            (item.add_state
                                                                ? capitalizeFirstLetter(item.add_state)
                                                                : "")}
                                                    </div>
                                                </span>
                                            </label>
                                        </li>
                                    );
                                })
                            ) : (
                                <li className="payment-methods__item mb-3">
                                    <label className="payment-methods__item-header">
                                        <span className="payment-methods__item-radio input-radio">
                                            <span className="input-radio__body">
                                                <input
                                                    type="radio"
                                                    className="input-radio__input"
                                                    name="checkout_payment_method"
                                                    checked={locationType == "new_address"}
                                                    onChange={(e) => {
                                                        setLocationType("new_address");
                                                    }}
                                                />
                                                <span className="input-radio__circle" />
                                            </span>
                                        </span>
                                        <FormattedMessage id="create_new_address" defaultMessage="Create new address" />
                                    </label>
                                </li>
                            )}

                            {/* <li className="payment-methods__item">
                                <label className="payment-methods__item-header">
                                    <span className="payment-methods__item-radio input-radio">
                                        <span className="input-radio__body">
                                            <input
                                                type="radio"
                                                className="input-radio__input"
                                                name="checkout_payment_method"
                                                checked={locationType == "gps"}
                                                onChange={(e) => setLocationType("gps")}
                                            />
                                            <span className="input-radio__circle" />
                                        </span>
                                    </span>
                                    <span className="payment-methods__item-title">
                                        <FormattedMessage id="your_current_location" defaultMessage="Your Current Location" />
                                    </span>
                                </label>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <div className="row mt-4 justify-content-center">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-9">
                        <button
                            disabled={!locationType}
                            className={"btn btn-primary w-100 mb-4" + (apiLoading ? " btn-loading" : "")}
                            onClick={() => handleCurrentLocation()}
                        >
                            <FormattedMessage id="continue" defaultMessage="Continue" />
                        </button>
                    </div>
                    <p className="font-weight-bold">
                        <FormattedMessage
                            id="delivery_method_modal_body_1"
                            defaultMessage="*This setup is required before proceed with your order."
                        />
                    </p>
                </div>
            </div>
        );
    };

    const renderPickUpStoreList = () => {
        if (pickUpMode == 1) {
            //first step
            return (
                <div className="py-3">
                    {backToPrevious}
                    {closeButton}
                    <h4>
                        <FormattedMessage id="delivery_method_modal_pickup_2" defaultMessage="Select Your Store Location" />
                    </h4>
                    <div className="scroll-list-container row">
                        {availableStoreList?.length > 0 ? (
                            availableStoreList.map((item) => {
                                return (
                                    <ul
                                        key={item.store_code}
                                        className="menu menu--layout--classic c-pointer col-12 col-sm-6"
                                        style={{ padding: 0 }}
                                        onClick={() => handleSelectStore(item)}
                                    >
                                        <li className="card mx-2 h-100">
                                            <a className="h-100">
                                                {item.store_name}
                                                <br />
                                                <div style={{ paddingTop: "3px" }}>
                                                    <small>
                                                        <i className="fas fa-map-marker-alt text-color-theme"></i>&nbsp;{item.store_address}
                                                    </small>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                );
                            })
                        ) : (
                            <BlockNoResult message="There are no stores available 30KM within your location range" textSize="small" />
                        )}
                    </div>
                </div>
            );
        } else if (pickUpMode == 2) {
            //second step
            return (
                <div>
                    {backToPrevious}

                    <div className="py-3 mt-1">
                        <h4>
                            <FormattedMessage id="delivery_method_modal_header" defaultMessage="Choose Your Delivery Method" />{" "}
                        </h4>
                        <div className="row m-3 mb-4 pt-3">
                            <div className="col-6 d-flex align-items-center justify-content-center">
                                <button
                                    disabled={
                                        selectedStore.store_pickdel != DELIVERY_METHOD && selectedStore.store_pickdel != DELIVERY_N_PICKUP
                                    }
                                    className="delivery-method-card card"
                                    onClick={() => handleChangeType(DELIVERY_METHOD)}
                                >
                                    <img src={"/images/icons/delivery-truck.png"} />
                                    <div className="pt-1">
                                        <FormattedMessage id="modal_delivery" defaultMessage="Delivery" />{" "}
                                    </div>
                                </button>
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-center">
                                <button
                                    disabled={
                                        selectedStore.store_pickdel != PICKUP_METHOD && selectedStore.store_pickdel != DELIVERY_N_PICKUP
                                    }
                                    className="delivery-method-card card"
                                    onClick={() => handleChangeType(PICKUP_METHOD)}
                                >
                                    <img src={"/images/icons/shop.png"} />
                                    <div className="pt-1">
                                        <FormattedMessage id="modal_pickup" defaultMessage="Store Pick-up" />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <p>
                        *
                        <FormattedMessage
                            id="delivery_method_modal_body_2"
                            defaultMessage="Change method or change store will clear your cart"
                        />
                    </p>
                </div>
            );
        } else if (pickUpMode == 3) {
            //third step
            return (
                <div className="py-3">
                    {/* {backToPrevious} */}

                    <h4>
                        <FormattedMessage id="delivery_method_modal_pickup_3" defaultMessage="Set preferred Date and Time" />
                    </h4>
                    <div className="px-3">
                        <div className="text-left  h-100 p-3">
                            {selectedStore.store_name}
                            <br />
                            <div>
                                <small>
                                    <i className="fas fa-map-marker-alt text-color-theme"></i>&nbsp;{selectedStore.store_address}
                                </small>
                            </div>
                        </div>
                        {/* </a> */}
                        <Flatpickr
                            className={`form-control mt-3`}
                            name="booking_time"
                            data-enable-time
                            value={selectedDate}
                            onChange={(date) => {
                                setSelectedDate(date);
                            }}
                            options={{
                                inline: true,
                                minDate: moment().add(pickUpBufferDay, "days").format("YYYY-MM-DD"),
                                maxDate: moment().add(6, "months").format("YYYY-MM-DD"),
                                minTime: pickUpTimeRange?.pickup_min_time_range,
                                maxTime: pickUpTimeRange?.pickup_max_time_range,
                                disable: [(val) => handleDisableDays(val, pickUpTimeRange?.pickup_days_range)],
                            }}
                        />
                        <div className="row mt-4 justify-content-center">
                            <div className="col-12 mb-2">
                                <button className="btn btn-primary w-100" onClick={() => confirmPickUpSetting()}>
                                    <FormattedMessage id="save" defaultMessage="Save" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else return 0;
    };

    return doneLoad ? (
        <Modal isOpen={modalVisible} centered size="lg">
            <ModalBody className="text-center">{pickUpMode == 0 ? renderSelectLocationSection() : renderPickUpStoreList()}</ModalBody>
        </Modal>
    ) : null;
}

export default DeliveryMethodModal;
