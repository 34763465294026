// react
import React from "react";

// data stubs
import theme from "../../data/theme";
import { FormattedMessage } from "react-intl";

export default function FooterContacts(props) {
    const { companyContact } = props;

    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title"><FormattedMessage id="footer.storeInfo" defaultMessage="Store Information" /></h5>

            <ul className="footer-contacts__contacts">
                {/* {companyContact?.company_name ? (
                    <li>
                        <h6 style={{ fontWeight: 600 }}>{companyContact?.company_name}</h6>
                    </li>
                ) : null}

                <br /> */}

                {companyContact?.company_address1 ? (
                    <>
                        <b className="footer-contacts__text"><FormattedMessage id="footer.address" defaultMessage="Address" /></b>

                        <li>
                            {companyContact?.company_address1 + (companyContact?.company_address2 ? companyContact?.company_address2 : "")}
                        </li>
                    </>
                ) : null}
                <br />
                {companyContact?.company_email ? (
                    <>
                        <b className="footer-contacts__text"><FormattedMessage id="footer.email" defaultMessage="Email" /></b>

                        <li>
                            <a href={"mailto:" + companyContact?.company_email}>{companyContact?.company_email}</a>
                        </li>
                    </>
                ) : null}

                <b className="footer-contacts__text"><FormattedMessage id="footer.contact" defaultMessage="Contact" /></b>
                {companyContact?.company_tel ? (
                    <li>
                        {/* Tel : <a href={"tel:" + companyContact?.company_tel}>{companyContact?.company_tel}</a> */}
                        <FormattedMessage id="footer.tel" defaultMessage="Tel" /> : {companyContact?.company_tel}
                    </li>
                ) : null}

                <li><FormattedMessage id="footer.whatsapp" defaultMessage="WhatsApp" /> : {'016-7712327'}</li>
                {/* {companyContact?.company_fax ? (
                    <>
                        <li><FormattedMessage id="footer.fax" defaultMessage="Fax"/> : {companyContact?.company_fax}</li>
                    </>
                ) : null} */}
            </ul>
        </div>
    );
}
