import { CART_ADD_ITEM, CART_REMOVE_ITEM, SYNC_MYCART } from "./cartActionTypes";

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */
function findItemIndex(items, product) {
    return items.findIndex((item) => {
        if (item.product_code !== product.product_code) {
            return false;
        }

        return true;
    });
}

function calcSubtotal(items) {
    return items.reduce((subtotal, item) => subtotal + parseInt(item.product_quantity) * parseFloat(item.product_price), 0);
}

function calcQuantity(items) {
    return items.reduce((quantity, item) => quantity + parseInt(item.product_quantity), 0);
}

function calcTotal(subtotal, extraLines) {
    return subtotal + extraLines.reduce((total, extraLine) => total + extraLine.price, 0);
}

function addItem(state, product, quantity) {
    const itemIndex = findItemIndex(state.items, product);

    let newItems;
    if (itemIndex === -1) {
        newItems = [
            ...state.items,
            {
                ...product,
                product_quantity: quantity,
            },
        ];
    } else {
        const item = state.items[itemIndex];
        newItems = [
            ...state.items.slice(0, itemIndex),
            {
                ...item,
                product_quantity: parseInt(item.product_quantity) + quantity,
                total: (parseInt(item.product_quantity) + quantity) * parseFloat(item.product_price),
            },
            ...state.items.slice(itemIndex + 1),
        ];
    }

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);

    return {
        ...state,
        subtotal,
        total,
        items: newItems,
        quantity: calcQuantity(newItems),
    };
}

function removeItem(state, itemCode) {
    const { items } = state;
    let newItems = [];
    //clear all cart if 0
    if (itemCode !== 0) {
        newItems = items.filter((item) => item.product_code !== itemCode);
    }
    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);
    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        subtotal,
        total,
    };
}

const initialState = {
    quantity: 0,
    items: [],
    subtotal: 0,
    extraLines: [
        // shipping, taxes, fees, .etc
        {
            type: "shipping",
            title: "Shipping",
            price: 25,
        },
        {
            type: "tax",
            title: "Tax",
            price: 0,
        },
    ],
    total: 0,
};

function syncCartList(state, list) {
    return { ...state, items: list, quantity: calcQuantity(list), subtotal: calcSubtotal(list) };
}

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case SYNC_MYCART:
            return syncCartList(state, action.products);

        case CART_ADD_ITEM:
            return addItem(state, action.product, action.quantity);

        case CART_REMOVE_ITEM:
            return removeItem(state, action.itemCode);

        default:
            return state;
    }
}
