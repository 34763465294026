// react
import React from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import { Cart16Svg } from "../../svg";
import { decodeChineseText, getNoImg, url } from "../../services/utils";

function Suggestions(props) {
    const { context, className, products } = props;
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);

    const list =
        products &&
        products.map((product) => (
            <li key={product.product_code} className="suggestions__item">
                <div className="suggestions__item-image product-image">
                    <div className="product-image__body">
                        <img
                            className="product-image__img"
                            src={product.product_picture}
                            onError={(e) => (e.target.src = getNoImg())}
                            alt={product.product_code}
                        />
                    </div>
                </div>
                <div className="suggestions__item-info">
                    <Link className="suggestions__item-name" to={url.product(product)}>
                        {decodeChineseText(product.product_name)}
                    </Link>
                </div>
                <div className="suggestions__item-price">{<Currency value={product.product_price} />}</div>
            </li>
        ));

    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">{list}</ul>
        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);
