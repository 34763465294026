import { getHeader } from "../services/utils";
import { getMemberCode } from "./auth";
import store from "../store";
import { syncWishlist } from "../store/wishlist";
const axios = require("axios");

export async function getFavoriteProducts() {
    let selectedPickUpStore = store.getState().user.deliveryMethod?.store?.store_no;
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/favorite_listing/" + getMemberCode()+"?store="+selectedPickUpStore;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                store.dispatch(syncWishlist(data));
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function addFavoriteProduct(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/add_favorite/" + getMemberCode(), data, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function deleteFavoriteProduct(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/delete_favorite/" + getMemberCode(), data, {
                headers: getHeader(),
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
