import { UPDATE_USER, UPDATE_NOTIFICATION, UPDATE_DELIVERY_METHOD, OPEN_DELIVERY_MODAL,SET_USER_ADDRESS } from "./userActionTypes";

// eslint-disable-next-line import/prefer-default-export
export function updateUser(profile) {
    return {
        type: UPDATE_USER,
        profile,
    };
}

export function updateNotification(notification, updateMode) {
    return {
        type: UPDATE_NOTIFICATION,
        notification,
        updateMode,
    };
}

export function updateDeliveryMethod(method) {
    return {
        type: UPDATE_DELIVERY_METHOD,
        method,
    };
}

export function openDeliveryModal(visible) {
    return {
        type: OPEN_DELIVERY_MODAL,
        visible,
    };
}

export function setUserAddress(address) {
    return {
        type: SET_USER_ADDRESS,
        address,
    };
}
